<template>
  <transition name="fade" mode="out-in">
    <div class="blog-page-container">
      <nav-component></nav-component>
      <div class="blog-hero">
        <h2>BLOG</h2>
      </div>
      <colours-bar class="home-top-colours"></colours-bar>
      <span v-if="loading">Loading...</span>
      <div class="blog-posts-container">
        <div class="three-block">
          <div class="main-blog-feature blog-callout">
            <span v-if="loading">Loading...</span>
            <router-link :to="{ name: 'blog-post', params: { slug: firstPost.slug } }">
              <img v-if="firstPost.featured_image" :src="firstPost.featured_image" class="post-img" alt="">
              <div class="copy">


                <p v-if="!firstPost.categories" class="blog-info">loading...</p>
                <p v-else class="blog-info">{{ firstPost.published | moment("MM.DD.YY") }}  |  {{ firstPost.categories[0].name }}</p>
                


                <h2>{{ firstPost.title }}</h2>
                <p class="teaser charcoal">{{ firstPost.summary }}</p>
                <router-link :to="{ name: 'blog-post', params: { slug: firstPost.slug } }"><button class="read-more">READ MORE</button></router-link>
              </div>
            </router-link>
          </div>

          <div class="two-stack">
            <div class="subscribe">
              <p class="lil-bit-bigger">Subscribe to the GRS blog<br>
              for the latest updates</p>
              <div id="signupForm"></div>
            </div>
          </div>
        </div>

        <div class="secondary-blog-posts">
          <div class="row">
            <div v-if="!posts.data">loading...</div>
            <ul v-else>
              <li v-for="post in posts.data" :key="post.slug" class="list-post-container">
                <router-link :to="{ name: 'blog-post', params: { slug: post.slug } }">
                  <div class="post">
                    <div class="post-img-holder" :style="{ backgroundImage: `url('${ post.featured_image }')` }"></div>
                    <div class="post-copy">
                      <p class="blog-info">{{ post.published | moment("MM.DD.YY") }}  |  {{ post.categories[0].name }}</p>
                      <h3>{{ post.title }}</h3>
                      <p class="charcoal">{{ post.summary }}</p>
                      <router-link :to="{ name: 'blog-post', params: { slug: post.slug } }"><p class="read-more-wordmark">READ MORE ></p></router-link>
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <!-- <div class="load-more-btn">
            <button class="learn-more">LOAD MORE</button>
          </div> -->
        </div>
      </div>
      <footer-component></footer-component>
    </div>
  </transition>
</template>

<script>

  import Butter from 'buttercms'
  const butter = Butter('4261c88920a47a48ccc3e6f4c8172f6cff6f9b9e');

  export default {
    name: 'blog',
    data: function() {
      return {
        bgImage: require('../assets/img/select-arrow.png'),
        loading: true,
        firstPost: {},
        posts: [],
        cats: []
      }
    },
    methods: {
      fetchBlogData () {
        butter.post.list({ page: 1, page_size: 30 })
        .then((response) => {
          this.loading = false
          this.firstPost = response.data.data.shift()
          this.posts = response.data
        }).catch((response) => {
          console.log(response)
        })

        butter.category.list()
        .then((resp) => {
          this.cats = resp.data.data
          // console.log(resp.data.data)
        }).catch((resp) => {
          console.log(resp)
        })
      },
    },
    mounted () {
      this.fetchBlogData();

      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);

      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "9304889",
            formId: "8acb20b3-a278-4bae-9460-b3e4cd64a6fa",
            target: "#signupForm"
          })
        }
      })
    }
  }

</script>

<style lang="scss">

  .blog-page-container {
    /*HEADER/HERO*/
    .blog-hero {
      background-image: url(../assets/img/blog-hero.jpg);
      background-position: center;
      -webkit-background-size: contain;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 80px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      
    }
    .blog-hero h2 {
      color: #fff;
      padding-top: 35px;
      font-size: 26px;
      letter-spacing: 6px;
      font-weight: 100;
    }

    /*MAIN BLOG FEATURES*/
    .three-block {
      width: 80%;
      max-width: 1200px;
      margin: 60px auto 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .blog-callout {
      width: 66%;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 20px;
      min-height: auto;
      margin-bottom: 40px;
      margin: 0 0 40px 0;
      border-bottom: 10px solid #00b1ff;
    }
    .blog-callout img { width: 100%; }
    .blog-callout .copy {
      padding: 20px 40px;
    }
    .blog-callout h2 {
      font-size: 30px;
      margin-bottom: 0;
    }

    

    .two-stack {
      width: 32%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 40px;
    }
    .search, 
    .subscribe {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0 20px;
      height: 100%;
    }
    .search { background-color: #00b1ff; }
    .subscribe { background-color: #52b100; }
    .subscribe p { margin-top: 0; }
    p.lil-bit-bigger { font-size: 22px; }
    p.lil-bit-bigger#signup-confirm {
      display: none;
      margin-top: 20px;
    }

    #signupForm { width: 100% !important; }

    .hs-form-field {
      position: relative;
      margin-bottom: 30px;
    }

    ul.no-list.hs-error-msgs{
      position: absolute;
      margin: 0;
      list-style: none;
      padding-left: 10px;
      top: 48px;
    }

    .hs_error_rollup { display: none; }

    #signupForm input {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #dbdbdb;
      background-color: #ffffff;
      border-radius: .25em;
      box-shadow: none;
      font-size: 16px;
      outline: none;
      color: #727272;
      padding: 12px 16px 12px 10px;
      line-height: 16px;
      margin-bottom: 10px;

      &.invalid.error { border: 1px solid #FD0000; }

      &.hs-button.primary.large {
        width: fit-content;
        width: -moz-fit-content;
        text-align: center;
        color: #fff;
        background-color: #00B1FF;
        background-image: none !important;
        border-color: #00B1FF !important;
        padding: 10px 20px;
        font-size: 18px !important;
        transition: 0.25s;
        border-width: 2px;
        border-style: solid;
        position: relative;
        border-radius: 10px;
        font-weight: 500;
        margin: 0;
        cursor: pointer;
        text-transform: uppercase;
        font-family: mr-eaves-xl-modern, sans-serif;

        &:hover {
          background-color: #fff;
          color: #00B1FF;
        }
      }
    }

    .blog-posts-container p.blog-info {
      color: #00b1ff;
      letter-spacing: 4px;
      font-size: 14px;
    }

    .read-more {
      border: 1px solid #ff6b00;
      background-color: #ff6b00;
      border-radius: 5px;
      padding: 8px 25px;
      cursor: pointer;
      transition: all 0.125s linear;
    }
    .read-more:hover {
      background-color: #fff;
      color: #ff6b00;
    }

    /*SECONDARY/OLDER BLOG POSTS*/

    .blog-post-container .secondary-blog-posts { padding-top: 0 !important; }
    .secondary-blog-posts {
      background-color: #fff;
    }
    .secondary-blog-posts ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    .secondary-blog-posts ul li {
      width: 32%;
      margin-bottom: 20px;
    }
    .secondary-blog-posts .row {
      width: 80%;
      max-width: 1200px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 auto;
      padding-bottom: 60px;
    }

    .row .post {
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 20px;
      min-height: 450px;
      border-bottom: 10px solid #00b1ff;
      min-height: 480px;
    }
    .row .post img { width: 100%; }

    .post-img-holder {
      width: 100%;
      height: 147px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .post-copy { padding: 0 15px; }

    .post-copy h3 {
      font-size: 20px;
      letter-spacing: 1px;
      margin-bottom: 0;
      font-weight: 300;
      color: #636466;
    }

    p.read-more-wordmark{
      color: #ff6b00;
      margin-top: 0px;
      font-weight: 400;
      cursor: pointer;
    }

    .load-more-btn {
      text-align: center;
      padding-bottom: 60px;
    }
    .load-more-btn button {
      background-color: #00b1ff;
      padding: 10px 25px;
      border: 1px solid #00b1ff;
      border-radius: 5px;
      font-size: 18px;
      transition: all 0.125s linear;
    }
    .load-more-btn button:hover {
      background-color: #fff;
      border: 1px solid #222;
      color: #222;
    }
  }

  @-moz-document url-prefix() { 
    .blog-hero h2 { font-weight: 300 !important; }
  }

  @media (max-width: 800px) {
    .three-block { flex-direction: column; }

    .blog-callout,
    .two-stack { width: 100%; }
    .blog-callout { margin-right: 0; }

    .search, 
    .subscribe { height: 300px; }

    .secondary-blog-posts .row {
      flex-direction: column;
      padding-bottom: 0;
    }
    .secondary-blog-posts ul li { width: 100%; }
    .secondary-blog-posts .row .post { 
      width: 100%;
      margin-bottom: 60px;
    }
  }
</style>
