<template>
  <!-- <transition name="fade" mode="out-in"> -->
    <div class="about-page-container">
      <nav-component></nav-component>
      <div class="about-hero">
        <h2>ABOUT</h2>
      </div>
      <colours-bar class="home-top-colours"></colours-bar>
      <div class="about-body">
        <div class="what-we-do">
          <img class="fade-left in-view-trans" src="@/assets/img/what-we-do.jpg" alt />
          <div class="copy">
            <h2>What We Do</h2>
            <p
              class="charcoal"
            >We make it easy to delight your customers and employees with the coolest rewards on the planet. Global Reward Solutions is a cloud-based reward management system – with local and global fulfillment capabilities – that enables your business to increase the performance of recognition programs, sales programs, customer loyalty programs, employee engagement programs, marketing campaigns, and contests. We source and consolidate rewards from hundreds of suppliers around the globe and present them in a beautifully designed, easy-to-use interface.</p>
          </div>
        </div>
        <div class="our-story">
          <div class="copy">
            <h2>Our Story</h2>
            <p
              class="charcoal"
            >Global Reward Solutions is the world’s first cloud-based solution that connects hundreds of global suppliers through an innovative reward engine to thousands of customers around the world. By consolidating a vast network of global suppliers into a single dashboard, we have automated reward data and order fulfillment, enabling our clients to choose from millions of reward options in 185 countries.</p>

            <p
              class="charcoal"
            >By combining our expertise and strategic thinking with cloud technology, we rewrote the rules for retail loyalty and channel incentive programs and transformed reward management and fulfillment. Today, GRS is the recognized pioneer in the incentives and rewards industry.</p>

            <p
              class="charcoal"
            >We deliver locally-sourced merchandise to 185 countries and convenient virtual rewards almost anywhere in the world. Our innovative rewards dashboard makes it easy to create customized client galleries and track order status. Global Reward Solutions supplies a worldwide community of recognition, incentives, training, and reward experts in North America, South America, Africa, UK, Europe, Middle East, Asia, India, and Australia. We are also the only reward platform that follows all global tax compliance regulations, making it easier than ever to implement and manage your program.</p>
          </div>
          <div class="img-stack">
            <img
              class="fade-right with-transition-delay in-view-trans"
              src="@/assets/img/our-story1.jpg"
              alt
            />
            <img class="fade-right in-view-trans" src="@/assets/img/our-story2.jpg" alt />
          </div>
        </div>
      </div>
      <div class="global-team">
        <h2>Our Global Team</h2>
        <div class="team-bio-container" id="leadership">
          <div class="row">
            <div class="bio">
              <img class="avatar" src="@/assets/img/rob.png" alt />
              <h2>Rob Purdy</h2>
              <p class="charcoal">Founder & Chief Executive Officer</p>
              <div class="social-icons">
                <a target="_blank" href="https://www.linkedin.com/in/rob-purdy-3a2a2a/">
                  <img src="@/assets/img/linkedin-bio.png" alt />
                </a>
              </div>
            </div>

            <!-- <div class="bio">
              <img class="avatar" src="@/assets/img/john.png" alt />
              <h2>John Hayes</h2>
              <p class="charcoal">Chief Financial Officer</p>
              <div class="social-icons">
                <a target="_blank" href="https://www.linkedin.com/in/john-hayes-3b62634/">
                  <img src="@/assets/img/linkedin-bio.png" alt />
                </a>
              </div>
            </div> -->

            <div class="bio">
              <img class="avatar" src="@/assets/img/adam.png" alt />
              <h2>Adam Small</h2>
              <p class="charcoal">Vice President, Client Services & Business Development</p>
              <div class="social-icons">
                <a target="_blank" href="https://www.linkedin.com/in/adam-small-1755101/">
                  <img src="@/assets/img/linkedin-bio.png" alt />
                </a>
              </div>
            </div>

            <div class="bio">
              <img class="avatar" src="@/assets/img/jim.png" alt />
              <h2>Jim Maga</h2>
              <p class="charcoal">Vice President, Global Procurement</p>
              <div class="social-icons">
                <a target="_blank" href="https://www.linkedin.com/in/jim-maga-2402223a/">
                  <img src="@/assets/img/linkedin-bio.png" alt />
                </a>
              </div>
            </div>
          </div>

          <div class="row">
            

            <div class="bio">
              <img class="avatar" src="@/assets/img/kevin.png" alt />
              <h2>Kevin Ireland</h2>
              <p class="charcoal">Vice President, Operations</p>
              <div class="social-icons">
                <a target="_blank" href="https://www.linkedin.com/in/kevin-ireland-7328377/">
                  <img src="@/assets/img/linkedin-bio.png" alt />
                </a>
              </div>
            </div>

            <div class="bio">
              <img class="avatar" src="@/assets/img/rick.png" alt />
              <h2>Rick Matthews</h2>
              <p class="charcoal">VP, Asia Pacific Operations</p>
              <div class="social-icons">
                <a target="_blank" href="https://www.linkedin.com/in/richardtmatthews/">
                  <img src="@/assets/img/linkedin-bio.png" alt />
                </a>
              </div>
            </div>

            <div class="bio">
              <img class="avatar" src="@/assets/img/sam.png" alt />
              <h2>Sam Henechowicz</h2>
              <p class="charcoal">Vice President, Cloud Services</p>
              <div class="social-icons">
                <a target="_blank" href="https://www.linkedin.com/in/samhenechowicz/">
                  <img src="@/assets/img/linkedin-bio.png" alt />
                </a>
              </div>
            </div>
          </div>

          <div class="row">
            
            <div class="bio">
              <img class="avatar" src="@/assets/img/phil.png" alt />
              <h2>Philip Daly</h2>
              <p class="charcoal">Vice President, Infrastructure and Information Security</p>
              <div class="social-icons">
                <a target="_blank" href="https://www.linkedin.com/in/philipdaly/">
                  <img src="@/assets/img/linkedin-bio.png" alt />
                </a>
              </div>
            </div>

            <!-- <div class="bio">
              <img class="avatar" src="@/assets/img/mike.png" alt />
              <h2>Michael Dell</h2>
              <p class="charcoal">Senior Director, Global Operations</p>
              <div class="social-icons">
                <a target="_blank" href="https://www.linkedin.com/in/michaeljdell/">
                  <img src="@/assets/img/linkedin-bio.png" alt />
                </a>
              </div>
            </div> -->

            <div class="bio">
              <img class="avatar" src="@/assets/img/mel.png" alt />
              <h2>Melanie Corasaniti</h2>
              <p class="charcoal">Director, Client Services</p>
              <div class="social-icons">
                <a target="_blank" href="https://www.linkedin.com/in/melanie-corasaniti-087b4329/">
                  <img src="@/assets/img/linkedin-bio.png" alt />
                </a>
              </div>
            </div>

            <!-- <div class="bio">
              <img class="avatar" src="@/assets/img/rick-c.png" alt />
              <h2>Rick Clarke</h2>
              <p class="charcoal">Director, Business Development</p>
              <div class="social-icons">
                <a target="_blank" href="https://www.linkedin.com/in/rickclarke1/">
                  <img src="@/assets/img/linkedin-bio.png" alt />
                </a>
              </div>
            </div> -->

            <div class="bio">
              <img class="avatar" src="@/assets/img/nate.png" alt />
              <h2>Nathan Kitchner</h2>
              <p class="charcoal">Director, Procurement - Europe</p>
              <div class="social-icons">
                <a target="_blank" href="https://www.linkedin.com/in/nathanpk1/">
                  <img src="@/assets/img/linkedin-bio.png" alt />
                </a>
              </div>
            </div>
          </div>

          <div class="row">
            

            <div class="bio">
              <img class="avatar" src="@/assets/img/oscar.png" alt />
              <h2>Oscar Nieves</h2>
              <p class="charcoal">Director, Global Sales</p>
              <div class="social-icons">
                <a target="_blank" href="https://www.linkedin.com/in/omnieves/">
                  <img src="@/assets/img/linkedin-bio.png" alt />
                </a>
              </div>
            </div>
            <div class="bio">
              <img class="avatar" src="@/assets/img/paul.png" alt />
              <h2>Paul Murphy</h2>
              <p class="charcoal">Director, Global Order Management</p>
              <div class="social-icons">
                <a target="_blank" href="https://www.linkedin.com/in/paul-murphy-863ab33/">
                  <img src="@/assets/img/linkedin-bio.png" alt />
                </a>
              </div>
            </div>

            <div class="bio">
              <img class="avatar" src="@/assets/img/neil.png" alt />
              <h2>Neil Wills</h2>
              <p class="charcoal">Director, User Experience</p>
              <div class="social-icons">
                <a target="_blank" href="https://www.linkedin.com/in/neil-wills-22b215b4/">
                  <img src="@/assets/img/linkedin-bio.png" alt />
                </a>
              </div>
            </div>
          </div>

          <div class="row">
            

            <div class="bio">
              <img class="avatar" src="@/assets/img/sandra.png" alt />
              <h2>Sandra Campbell</h2>
              <p class="charcoal">Manager, Customer Care</p>
              <div class="social-icons">
                <a target="_blank" href="https://www.linkedin.com/in/sandra-campbell-152b0474/">
                  <img src="@/assets/img/linkedin-bio.png" alt />
                </a>
              </div>
            </div>
            <div class="bio">
              <img class="avatar" src="@/assets/img/nicole.png" alt />
              <h2>Nicole Dabike</h2>
              <p class="charcoal">Vendor Relations Manager, Latin America & Caribbean</p>
              <div class="social-icons">
                <a target="_blank" href="https://www.linkedin.com/in/nicole-dabik%C3%A9-65802a25/">
                  <img src="@/assets/img/linkedin-bio.png" alt />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="working">
        <div class="working-container">
          <div class="icon">
            <img src="@/assets/img/working-icon.png" alt />
          </div>
          <div class="working-copy">
            <h2>Working At Global Reward Solutions</h2>
            <p>At GRS we believe the success of our employees is tied with the success of our business. We hire the smartest and most enthusiastic employees and we keep them by giving them awesome perks and support. <!-- Did we mention we have an in-house chef? --></p>
            <a href="https://www.fitzii.com/fitzii_widget/271?key=bn7x0ss" target="_blank">
              <button class="learn-more-btn">VIEW OPENINGS</button>
            </a>
          </div>
        </div>
      </div>
      <footer-component></footer-component>
    </div>
  <!-- </transition> -->
</template>

<script>
export default {
  data: function () {
    return {
      name: 'about'
    }
  },
  methods: {
    leadershipButton () {}
  }
}
</script>

<style>
/*HEADER/HERO*/
.about-hero {
  background-image: url(../assets/img/about-hero.jpg);
  background-position: center;
  -webkit-background-size: contain;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-hero h2 {
  color: #fff;
  padding-top: 35px;
  font-size: 26px;
  letter-spacing: 6px;
  font-weight: 100;
}

/*MAIN ABOUT SECTION*/
.about-body {
  padding: 80px 0;
  max-width: 1500px;
  margin: 0 auto;
}

.what-we-do {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.what-we-do img {
  width: 50%;
}
.what-we-do .copy {
  padding: 20px 100px 0 60px;
  width: 50%;
}
.what-we-do .copy h2 {
  margin-bottom: 10px;
}
.what-we-do,
.our-story {
  margin-bottom: 60px;
}

.our-story {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.our-story .copy {
  padding: 20px 60px 0 60px;
}

.img-stack {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.img-stack img {
  margin-bottom: 20px;
}

/*TEAMS SECTION*/
.global-team {
  background-color: #eee;
  padding: 60px 0;
  text-align: center;
}

.team-bio-container .row {
  margin: 50px 0;
  justify-content: space-around;
}
#leadership p.charcoal {
  padding: 0 40px;
}

.row .bio {
  width: 25%;
}

.bio .avatar {
  width: 210px;
  margin-bottom: 10px;
}
.bio h2 {
  font-size: 22px;
  margin-bottom: -8px;
}
.bio .social-icons img {
  width: 30px;
  margin: 0 5px;
}

/*TABBED NAV*/
/*  .tabs-component ul li,
  .tabs-component-panel { color: #000; }
  .tabs-component ul li a {
    text-decoration: none;
    color: #636466;
  }
  .tabs-component ul li:hover a { color: #fff; }
  .tabs-component {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .tabs-component ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: center;
    margin: 0 auto;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #acacac;
  }
  .tabs-component ul li { margin-bottom: 10px; }
  .tabs-component ul li a {
    background-color: #e1e1e1;
    color: #636466;
    font-size: 18px;
    font-weight: 300;
    padding: 10px 20px;
    margin: 0 4px;
    transition: all 0.125s linear;
    cursor: pointer;
  }
  .tabs-component ul li a:hover {
    background-color: #00b1ff;
    color: #fff;
  }
  .tabs-component ul li.is-active a {
    background-color: #00b1ff;
    color: #fff;
  }
  .tabs-component ul li.is-active a { color: #fff; }*/

/*WORKING AT GRS*/
.working {
  padding: 60px 0;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}
.working-container {
  margin: 0 auto;
  padding: 30px 60px;
  background-color: #071d32;
  border-bottom: 10px solid #838e98;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.working-container .icon img {
  width: 180px;
  max-width: none;
}
.working-copy {
  text-align: left;
  width: 60%;
}
.working-copy h2 {
  margin-bottom: 0;
  color: #fff;
}
.working-copy p {
  margin: 20px 0 25px;
}

@-moz-document url-prefix() {
  .about-hero h2 {
    font-weight: 300 !important;
  }
}

@media (max-width: 1100px) {
  .about-body {
    padding-bottom: 0;
  }
  .our-story {
    text-align: center;
  }
  .img-stack {
    width: 100%;
    margin-top: 15px;
  }
  .about-body p {
    max-width: 650px;
    text-align: left;
    margin: 0 auto;
  }
  .what-we-do,
  .our-story {
    flex-direction: column;
  }
  .what-we-do img {
    width: 90%;
    margin: 0 auto 40px;
  }
  .what-we-do .copy,
  .our-story .copy {
    width: 90%;
    padding: 0;
    margin: 0 auto;
    text-align: center;
  }

  .our-story {
    margin-bottom: 0;
  }
  .our-story .copy h2 {
    margin-bottom: 10px;
  }
  .img-stack img:first-of-type {
    margin-bottom: 40px;
    max-width: 90%;
    object-fit: contain;
  }

  .img-stack img:last-of-type {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .team-bio-container .row {
    flex-direction: column;
    margin: 0;
  }
  .team-bio-container .row .bio {
    width: 100%;
    margin-bottom: 40px;
  }

  .working-container {
    flex-direction: column;
    padding: 30px 10px;
  }
  .working-container .icon {
    margin-bottom: 30px;
  }

  .working-copy {
    width: 90%;
  }
}
</style>
