<template>
  <transition name="fade" mode="out-in">
    <span v-if="!post.data">Loading...</span>
    <div v-else class="blog-page-container">
      <nav-component></nav-component>
      <div class="blog-post-hero" :style="{ backgroundImage: `url('${ post.data.featured_image }')` }">
        <div class="gradient-container"></div>
      </div>
      <colours-bar class="home-top-colours"></colours-bar>
      
      <div class="post-container single-post">
        <div class="post">
          <!-- <p class="blog-info">01.28.19  |  EMPLOYEE REWARDS</p> -->
          <p class="blog-info">{{ post.data.published | moment("MM.DD.YY") }}  |  {{ post.data.categories[0].name }}</p>
          <h1 class="post-title">{{ post.data.title }}</h1>
          <div class="post-body" v-html="post.data.body"></div>
          <!-- <div class="share-with-social-icons">
            <p class="charcoal">Share this</p>
            <div class="icons-colour">
              <img src="@/assets/img/facebook-blog.png" alt="">
              <img src="@/assets/img/twitter-blog.png" alt="">
              <img src="@/assets/img/linkedin-blog.png" alt="">
              <img src="@/assets/img/email-blog.png" alt="">
            </div>
          </div> -->
        </div>
        <div class="two-stack">
          <div class="subscribe">
            <p class="lil-bit-bigger">Subscribe to the GRS blog<br>
            for the latest updates</p>
            <!-- <iframe src="https://go.power2motivate.com/l/97982/2019-05-31/jpnnw8" width="100%" height="auto" type="text/html" frameborder="0" allowTransparency="true" style="border: 0;min-height: 164px;"></iframe> -->

            <form id="mktoForm_1031"></form>
            
            <p class="lil-bit-bigger" id="signup-post-confirm">Thanks! We'll get back to you as soon as possible!</p>
          </div>
          <!-- <div class="categories">
            <h3>Categories</h3>
            <p>Employee Rewards (10)</p>
            <p>News (8)</p>
            <p>Retail Tips (9)</p>
            <p>Channel Incentive (1)</p>
            <p>Health and Wellness (6)</p>
          </div> -->
        </div>
      </div>
      <div class="blog-post-container">
        <div class="latest-blog-posts">
          <h2 class="latest-posts">Latest Posts</h2>
          <div class="row">
            <div class="post" v-if="latestPosts.data.length" v-for="postLatest in latestPosts.data" :key="postLatest.slug">
              <router-link :to="{ name: 'blog-post', params: { slug: postLatest.slug } }">
                <div class="post-img-holder" :style="{ backgroundImage: `url('${ postLatest.featured_image }')` }"></div>
                <div class="post-copy">
                  <h3>{{ postLatest.title }}</h3>
                  <router-link :to="{ name: 'blog-post', params: { slug: postLatest.slug } }"><p class="read-more-wordmark">READ MORE ></p></router-link>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <footer-component></footer-component>
    </div>
  </transition>
</template>

<script>
  import Butter from 'buttercms'
  const butter = Butter('4261c88920a47a48ccc3e6f4c8172f6cff6f9b9e');

  export default {
    name: 'blog-post',
    data: function() {
      return {
        loading: true,
        post: {},
        latestPosts: []
      }
    },
    methods: {
      fetchPost () {
        butter.post.retrieve( this.$route.params.slug )
        .then((response) => {
          this.loading = false
          this.post = response.data
          console.log(response.data.data.categories)
        }).catch((response) => {
          // console.log(response)
        })
      },
      fetchLatestPosts () {
        butter.post.list({ page: 1, page_size: 3 })
        .then((response) => {
          this.latestPosts = response.data
        }).catch((response) => {
          console.log(response)
        })
      }
    },
    created () {
      this.fetchPost()
      this.fetchLatestPosts()
    },
    mounted () {
      MktoForms2.loadForm("//app-sn05.marketo.com", "542-IGI-213", 1031);
      
      MktoForms2.whenReady(function (form){
        //Add an onSuccess handler
         form.onSuccess(function(values, followUpUrl){
          //get the form's jQuery element and hide it
          form.getFormElem().hide();

          document.getElementById('signup-post-confirm').style.display = 'block';
          //return false to prevent the submission handler from taking the lead to the follow up url.
          return false;
        });
      });
    },
    watch: {
      $route: {
        immediate: true,
        handler( to, from ) {
          this.fetchPost()
        }
      }
    }
  }
</script>

<style scoped>

  /*HEADER/HERO*/
  .blog-post-hero {
    background-position: center;
    -webkit-background-size: contain;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 0;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gradient-container {
    background-image: -webkit-gradient(linear,left bottom,left top,from(rgba(31,45,61,0)),color-stop(74%,#1f2d3d));
    background-image: -o-linear-gradient(bottom,rgba(31,45,61,0) 0,#1f2d3d 74%);
    background-image: linear-gradient(0deg,rgba(31,45,61,0) 0,#1f2d3d 74%);
    top: 0;
    left: 0;
    opacity: 0.5;
    width: 100%;
    height: 40%;
    position: absolute;
    box-sizing: border-box;
    
  }

  .post-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 60px 0 40px 0;
  }
  .post-container .post { width: 60%; }
  h1.post-title {
    font-weight: 300;
    color: #636466;
    margin-bottom: 30px;
    font-size: 46px;
  }
  .post-container .two-stack { width: 30%; }
  .post-container .post-body p { color: #4d4d4f; }
  .post-body p,
  .post-body p a { color: #4d4d4f !important; }

  .post-body h3 { color: #4d4d4f !important; }

  .post-container h3.post-quote {
    color: #00b1ff;
    border-left: 8px solid #00b1ff;
    font-weight: 600;
    line-height: 40px;
    padding-left: 15px;
    font-size: 30px;
  }

  .post-container .post-body img.post-img {
    width: 100%;
    margin: 20px 0;
  }

  .subscribe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 60px 20px;
  }

  .subscribe { 
    height: 200px;
    background-color: #52b100;
  }
  .subscribe p { margin-top: 0; }
  p.lil-bit-bigger { font-size: 22px; }
  p.lil-bit-bigger#signup-post-confirm {
    display: none;
    margin-top: 20px;
  }
  
  .input-btn-combo { width: 100%; }
  .input-btn-combo input {
    border: none;
    font-size: 16px;
    background-color: #e6e7e8;
    padding: 8px 5px;
    color: #636466;
    width: 80%;
  }
  .input-btn-combo button {
    border: none;
    padding: 8px 5px;
    background-color: #707070;
    font-size: 16px;
  }

  p.blog-info {
    color: #00b1ff;
    letter-spacing: 4px;
    font-size: 14px;
  }

  .share-with-social-icons p {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .share-with-social-icons .icons-colour img {
    width: 25px;
    margin-right: 10px;
  }

  .categories {
    background-color: #eeeeee;
    padding: 10px 20px;
  }
  .categories h3 { color: #636466; }
  .categories p { color: #636466; }

  

  /*SECONDARY/OLDER BLOG POSTS*/
  .blog-post-container .latest-blog-posts { padding-top: 0 !important; }
  .latest-blog-posts { background-color: #eeeeee; }
  .latest-blog-posts h2.latest-posts {
    text-align: center;
    margin: 0 0 30px 0;
    padding-top: 30px;
  }
  .latest-blog-posts .row {
    width: 80%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    padding-bottom: 60px;
  }
  .row .post {
    width: 30%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px;
    min-height: 276px;
    height: auto;
    border-bottom: 10px solid #00b1ff;
    background-color: #fff;
  }

  .post-img-holder {
    width: 100%;
    height: 147px;
    /*background-color: green;*/
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .post-copy { padding: 0 15px; }
  .post-copy h3 {
    font-size: 20px;
    letter-spacing: 1px;
    margin: 15px 0;
    font-weight: 300;
    color: #636466;
  }

  p.read-more-wordmark{
    color: #ff6b00;
    margin-top: 0px;
    font-weight: 400;
    cursor: pointer;
  }

  @media (max-width: 800px) {
    .post-container { flex-direction: column; }
    .blog-callout,
    .two-stack { width: 100%; }
    .blog-callout { margin-right: 0; }
 
    .subscribe { height: 200px; }

    .post-container .post { width: 100%; }
    /*.post-body { max-width: 100% !important; }*/

    .post-container .two-stack {
      width: 100%;
      margin-top: 40px;
    }

    .latest-blog-posts .row {
      flex-direction: column;
      padding-bottom: 0;
    }
    .latest-blog-posts .row .post { 
      width: 100%;
      margin-bottom: 60px;
    }
  }
</style>
