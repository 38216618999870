<template>
  <transition name="fade" mode="out-in">
    <div class="selection-page-container">
      <nav-component></nav-component>
      <div class="global-selection-hero">
        <h2>GLOBAL SELECTION</h2>
      </div>
      <colours-bar></colours-bar>
      <div class="global-selection-body">
        <h1 class="page-heading">Discover Our Amazing Reward Selection</h1>

        <!-- SEARCHABLE COUNTRY FIELDS -->

        <!-- <div class="search-input">
          <input type="text" placeholder="Search for your country"><button>GO</button>
        </div>-->
        <div class="selected-country">
          <p class="blue-selected-country">Over 10M+ rewards worldwide</p>
        </div>

        <div class="circle-diagrams">
          <div class="circle green">
            <h1>2,500+</h1>
            <p>Merchandise Brands</p>
          </div>
          <div class="circle pink">
            <h1>1M+</h1>
            <p>
              Hotel, Travel
              <br />&amp; Experiences
            </p>
          </div>
          <div class="circle orange">
            <h1>2,500+</h1>
            <p>Gift Card Brands</p>
          </div>
          <div class="circle purple">
            <h1>850+</h1>
            <p>
              Mobile Top-Up
              <br />Carriers
            </p>
          </div>
          <div class="circle lime">
            <h1>750,000+</h1>
            <p>Global Savings Offers</p>
          </div>
          <div class="circle rose">
            <h1>1.8M+</h1>
            <p>Charities</p>
          </div>
        </div>
        <div class="map-img fpo">
          <div class="inner-map">
            <img src="@/assets/img/map.png" alt />
          </div>
        </div>
        <div class="rewards-available">
          <h2>Global Rewards Summary</h2>
          <div class="rewards-container">
            <div class="row">
              <div class="reward-callout merchandise">
                <div class="header-img"></div>
                <div class="copy">
                  <h3>Merchandise</h3>
                  <p
                    class="charcoal"
                  >Our vast merchandise catalog offers thousands of unique products to members in over 85 countries. There’s something for everyone. We feature the world’s best-selling brands, including Apple, Bose, Sony, Morphy Richards and much more. Our products are sourced and shipped locally, which means fast 2-3 business day delivery. With new merchandise added daily, our catalog is continually refreshed, so there’s always something new to see, and no frustrating ‘out-of-stock’ messages.</p>
                </div>
              </div>
              <div class="reward-callout hotel-travel">
                <div class="header-img"></div>
                <div class="copy">
                  <h3>Hotel, Travel & Experiences</h3>
                  <p
                    class="charcoal"
                  >We’ve negotiated amazing discounts and unbeatable rates on hundreds of thousands of hotels, vacations and experiences. Choose from over 400,000 discounted hotels, over 100,000 highly discounted resorts, and over 17,000 excursions and guided tours. Last minute deals are also available, with insider access to hundreds of sold-out live shows like The Lion King or sports events such as the NBA and Super Bowl.</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="reward-callout gift">
                <div class="header-img"></div>
                <div class="copy">
                  <h3>Gift Cards</h3>
                  <p
                    class="charcoal"
                  >Our Rewards Cloud gives you access to over 850 retail gift card brands around the world. These convenient cards offer significant discounts at popular brands like Amazon and Starbucks. Cards are available both as digital and physical gift cards for fast and easy redemption.</p>
                </div>
              </div>
              <div class="reward-callout mobile">
                <div class="header-img"></div>
                <div class="copy">
                  <h3>Mobile Top-Up</h3>
                  <p
                    class="charcoal"
                  >Real-time mobile credits and rate plans are available from over 260 carriers globally and are instantly redeemable in over 85 countries.</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="reward-callout global-savings">
                <div class="header-img"></div>
                <div class="copy">
                  <h3>Global Savings</h3>
                  <p
                    class="charcoal"
                  >This exclusive feature offers members-only discounts on the world’s top brands and experiences. Save on movie tickets, fashion, restaurants, cruises, hotels and technology and much more.</p>
                </div>
              </div>
              <div class="reward-callout charities">
                <div class="header-img"></div>
                <div class="copy">
                  <h3>Charities</h3>
                  <p
                    class="charcoal"
                  >Giving back to the community can be the ultimate reward. That’s why you can spend your points on hundreds of supported charities such as United Way and The Sick Kids Foundation. We’ve even lowered the redemption values for our charities to make it even easier for you to use your points, while helping a great cause.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer-component></footer-component>
    </div>
  </transition>
</template>

<script>
export default {
  data: function () {
    return {
      name: 'global-selection'
    }
  }
}
</script>

<style scoped>
/*HEADER/HERO*/
.global-selection-hero {
  background-image: url(../assets/img/global-selection-hero.jpg);
  background-position: center;
  -webkit-background-size: contain;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.global-selection-hero h2 {
  color: #fff;
  padding-top: 35px;
  font-size: 26px;
  letter-spacing: 6px;
  font-weight: 100;
}

/*DYNAMIC SELECTION MAP SECTION*/
.global-selection-body {
  padding: 60px 0;
  text-align: center;
}

.global-selection-body h1.page-heading {
  font-weight: 300;
  color: #636466;
  margin-bottom: 60px;
  font-size: 46px;
}

.search-input {
  width: 40%;
  max-width: 400px;
  margin: 0 auto;
}
.search-input input {
  width: 70%;
  background-color: #e6e7e8;
  font-size: 16px;
  border: none;
  padding: 6px;
  color: #707070;
}
.search-input button {
  border: none;
  border-radius: 0;
  background-color: #707070;
  font-size: 14px;
  padding: 6px;
  padding-top: 8px;
}
.selected-country .blue-selected-country {
  font-size: 22px;
  color: #00b1ff;
  margin-top: 20px;
}

.circle-diagrams {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}
.circle-diagrams .circle {
  border-radius: 50%;
  padding: 20px;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.circle-diagrams .circle h1 {
  font-size: 28px;
  font-weight: ;
}
.circle-diagrams .circle p {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
}
.green {
  background-color: #7dc540;
}
.pink {
  background-color: #f140a9;
}
.orange {
  background-color: #ff9040;
}
.purple {
  background-color: #a77dcb;
}
.lime {
  background-color: #aee040;
}

.inner-map,
.inner-map img {
  width: 80%;
  margin: 0 auto;
  position: relative;
  top: -15px;
}

/*AVAILAVBLE REWARDS SECTION*/
.rewards-available {
  padding: 60px 0;
}
.rewards-available .rewards-container {
  width: 80%;
  max-width: 1100px;
  margin: 0 auto;
}
.rewards-available .rewards-container .row {
  justify-content: space-between;
}
.rewards-available .rewards-container .row .reward-callout {
  width: 49%;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 20px;
  min-height: 485px;
  margin-bottom: 40px;
}

.merchandise .header-img {
  background-image: url(../assets/img/merch-reward-header.jpg);
}
.reward-callout.merchandise {
  border-bottom: 10px solid #7dc540;
}
.hotel-travel .header-img {
  background-image: url(../assets/img/hotel-reward-header.png);
}
.reward-callout.hotel-travel {
  border-bottom: 10px solid #f140a9;
}
.gift .header-img {
  background-image: url(../assets/img/gift-reward-header.png);
}
.reward-callout.gift {
  border-bottom: 10px solid #ff9040;
}
.mobile .header-img {
  background-image: url(../assets/img/mobile-reward-header.png);
}
.reward-callout.mobile {
  border-bottom: 10px solid #a77dcb;
}
.global-savings .header-img {
  background-image: url(../assets/img/global-savings-reward-header.png);
}
.reward-callout.global-savings {
  border-bottom: 10px solid #aee040;
}
.charities .header-img {
  background-image: url(../assets/img/charities-reward-header.png);
}
.reward-callout.charities {
  border-bottom: 10px solid #f6a6cd;
}

.reward-callout h3 {
  font-size: 24px;
  margin: 0;
  font-weight: 300;
  color: #636466;
}

.header-img {
  height: 170px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.copy {
  margin: 10px 0;
  padding: 20px 0;
}
.copy h2 {
  font-size: 24px;
  margin-bottom: 0;
}
.copy p {
  padding: 0 30px;
}

@-moz-document url-prefix() {
  .global-selection-hero h2 {
    font-weight: 300 !important;
  }
}

@media (max-width: 800px) {
  .circle-diagrams {
    display: none;
  }

  .rewards-container .row {
    flex-direction: column;
  }

  .rewards-available {
    padding-bottom: 0;
  }
  .rewards-available .rewards-container .row .reward-callout {
    width: 100%;
  }
}
</style>
