<template>
  <transition name="fade" mode="out-in">
    <div class="resources-page-container">
      <nav-component></nav-component>
      <div class="resources-hero">
        <h2>RESOURCES</h2>
      </div>
      <colours-bar></colours-bar>
      <div class="resources-nav">
        <ul>
          <li>
            <p class="charcoal">ALL</p>
          </li>
          <li>
            <p class="charcoal">WHITE PAPERS</p>
          </li>
          <li>
            <p class="charcoal">CASE STUDIES</p>
          </li>
          <li>
            <p class="charcoal">VIDEOS</p>
          </li>
        </ul>
      </div>
      <div class="resources">
        <div class="row">
          <div class="resource w-p">
            <img src="@/assets/img/resource1.jpg" alt="">
            <div class="resource-copy">
              <p class="w-p-heading">WHITEPAPER</p>
              <h2>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</h2>
              <p class="charcoal">Epsum factorial non deposit quid pro quo hic escorol. Olypian quarrels et gorilla congolium sic ad nauseum...</p>
            </div>
            <!-- <button class="view-more">VIEW</button> -->
          </div>
          <div class="resource c-s">
            <img src="@/assets/img/resource2.jpg" alt="">
            <div class="resource-copy">
              <p class="c-s-heading">CASE STUDY</p>
              <h2>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</h2>
              <p class="charcoal">Epsum factorial non deposit quid pro quo hic escorol. Olypian quarrels et gorilla congolium sic ad nauseum...</p>
            </div>
          </div>
          <div class="resource video">
            <img src="@/assets/img/resource3.jpg" alt="">
            <div class="resource-copy">
              <p class="video-heading">VIDEO</p>
              <h2>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</h2>
              <p class="charcoal">Epsum factorial non deposit quid pro quo hic escorol. Olypian quarrels et gorilla congolium sic ad nauseum...</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="resource c-s">
            <img src="@/assets/img/resource4.jpg" alt="">
            <div class="resource-copy">
              <p class="c-s-heading">CASE STUDY</p>
              <h2>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</h2>
              <p class="charcoal">Epsum factorial non deposit quid pro quo hic escorol. Olypian quarrels et gorilla congolium sic ad nauseum...</p>
            </div>
          </div>
          <div class="resource w-p">
            <img src="@/assets/img/resource5.jpg" alt="">
            <div class="resource-copy">
              <p class="w-p-heading">CASE STUDY</p>
              <h2>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</h2>
              <p class="charcoal">Epsum factorial non deposit quid pro quo hic escorol. Olypian quarrels et gorilla congolium sic ad nauseum...</p>
            </div>
          </div>
          <div class="resource w-p">
            <img src="@/assets/img/resource6.jpg" alt="">
            <div class="resource-copy">
              <p class="w-p-heading">CASE STUDY</p>
              <h2>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</h2>
              <p class="charcoal">Epsum factorial non deposit quid pro quo hic escorol. Olypian quarrels et gorilla congolium sic ad nauseum...</p>
            </div>
          </div>
        </div>
      </div>
      <footer-component></footer-component>
    </div>
  </transition>
</template>

<script>
  export default {
    data: function() {
      return {
        name: 'resources'
      }
    }
  }
</script>

<style>
  /*HEADER/HERO*/
  .resources-page-container { background-color: #f1f2f2; }
  .resources-hero {
    background-image: url(../assets/img/resources-hero.jpg);
    background-position: center;
    -webkit-background-size: contain;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .resources-hero h2 {
    color: #fff;
    padding-top: 35px;
    font-size: 26px;
    letter-spacing: 6px;
    font-weight: 100;
  }

  /*RESOURCES*/
  .resources-nav {
    background-color: #f1f2f2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .resources-nav ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    padding: 0;
  }
  .resources-nav ul li { 
    margin: 0 20px;
    cursor: pointer;
  }
  .resources-nav p { transition: all 0.125s linear; }
  .resources-nav ul li:hover p { color: #00B1FF !important; }

  .resources {
    background-color: #fff;
    padding-top: 60px;
  }
  .resources .row {
    width: 80%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    padding-bottom: 60px;
  }

  .row .resource {
    width: 30%;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 20px;
    min-height: 340px;
    height: auto;
  }
  .row .resource img { width: 100%; }
  .resource-copy { padding: 0 15px; }

  .resource-copy h2 {
    font-size: 20px;
    letter-spacing: 1px;
    margin-bottom: 0;
  }

  .resource-copy p { margin-bottom: 30px; }
  p.w-p-heading {
    color: #00b1ff;
    letter-spacing: 2px;
    margin-bottom: 10px;
  }
  p.c-s-heading {
    color: #ee4d9b;
    letter-spacing: 2px;
    margin-bottom: 10px;
  }
  p.video-heading {
    color: #52b100;
    letter-spacing: 2px;
    margin-bottom: 10px;
  }

  .resource.w-p { border-bottom: 10px solid #00b1ff; }
  .resource.c-s { border-bottom: 10px solid #ee4d9b; }
  .resource.video { border-bottom: 10px solid #52b100; }

  @-moz-document url-prefix() { 
    .resources-hero h2 { font-weight: 300 !important; }
  }

  @media (max-width: 800px) {
    .resources-nav {
      justify-content: flex-start;
    }
    .resources-nav ul {
      flex-direction: column;
      justify-content: flex-start;
    }
    .resources-nav ul li { text-align: left; }
    .resources-nav ul li p { margin: 5px 0; }
    .resources { padding-bottom: 40px; }

    .resources .row {
      flex-direction: column;
      padding-bottom: 0;
    }
    .resources .row .resource {
      width: 100%;
      margin-bottom: 20px;
    }
    .resource:last-of-type { margin-bottom: 60px; }
  }
</style>
