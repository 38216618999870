<template>
  <div class="container nav-header">
    <fixed-header :fixed.sync="navTop" :threshold="50">
      <div
        class="navbar"
        :class="swapClass()"
      >
        <div class="logo-nav-container">
          <div class="logo">
            <svg-logo></svg-logo>
          </div>
          <div class="nav">
            <div
              id="mobile-nav-button"
              class="menu-toggle"
              @click="showMobileMenu = !showMobileMenu; hamburger()"
              ref="hamburger"
            >
              <svg
                class="icon icon-menu-toggle"
                aria-hidden="true"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
              >
                <g class="svg-menu-toggle">
                  <path class="line line-1" d="M5 13h90v14H5z" />
                  <path class="line line-2" d="M5 43h90v14H5z" />
                  <path class="line line-3" d="M5 73h90v14H5z" />
                </g>
              </svg>
            </div>
            <transition>
              <ul class="nav-list stroke" id="nav-menu" v-bind:class="{ open: showMobileMenu }">
                <li class="drop">
                  <a @click="toggle = !toggle">SOLUTIONS</a>
                  <transition name="fade">
                    <ul class="drop_menu" ref="dropDown" v-show="toggle">
                      <li>
                        <router-link to="/solutions/reward-cloud">Reward Cloud</router-link>
                      </li>
                      <li>
                        <router-link to="/solutions/global-savings">Global Savings</router-link>
                      </li>
                      <li>
                        <router-link to="/solutions/gift-codes">Gift Codes</router-link>
                      </li>
                    </ul>
                  </transition>
                </li>
                <li>
                  <router-link to="/global-selection">GLOBAL SELECTION</router-link>
                </li>
                <li>
                  <router-link to="/why-grs">WHY GRS</router-link>
                </li>
                <li>
                  <router-link to="/about">ABOUT</router-link>
                </li>
                <!-- <li>
                  <router-link to="/resources">RESOURCES</router-link>
                </li>-->
                <li>
                  <router-link to="/blog">BLOG</router-link>
                </li>
                <li>
                  <router-link to="/contact">CONTACT</router-link>
                </li>
                <li class="nav-get-started-li">
                  <router-link to="/contact">
                    <button class="nav-get-started-btn">GET STARTED</button>
                  </router-link>
                </li>
              </ul>
            </transition>
          </div>
        </div>
      </div>
    </fixed-header>
  </div>
</template>

<script>
import FixedHeader from "vue-fixed-header";
export default {
  components: {
    FixedHeader
  },
  data() {
    return {
      toggle: false,
      navTop: false,
      showMobileMenu: false,
      showNavbar: true,
      lastScrollPosition: 0
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    // display_drop_menu : function(){
    //   var menu = this.$refs.dropDown;
    //   // menu.style.display = (menu.style.display == 'none') ? 'flex' : 'none';
    // },
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 10) {
        return;
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
    hamburger: function() {
      var button = this.$refs.hamburger;
      // Toggle class "opened". Set also aria-expanded to true or false.
      if (button.className.indexOf("opened") !== -1) {
        button.className = button.className.replace(" opened", "");
        button.setAttribute("aria-expanded", "false");
        // alert('got here');
      } else {
        button.className += " opened";
        button.setAttribute("aria-expanded", "true");
      }
    },
    swapClass: function() {
      return {
        'white-bg' : this.navTop,
        'navbar--hidden' : !this.showNavbar
      }
    }
  }
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 20px;
}
li:last-of-type {
  margin-right: 0;
}

.container.nav-header {
  width: 100%;
  height: auto;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  background-color: #2e3192;
}
.container.nav-header a {
  color: #fff;
  text-decoration: none;
}
.container.nav-header a:active,
:focus {
  outline: none !important;
}

.logo-nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  max-width: 1600px;
  margin: 10px auto;
}

.logo-nav-container .logo {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1000;
}
.logo-nav-container .logo a {
  padding-bottom: 0 !important;
  height: auto;
  display: flex;
  justify-content: center;
  
}
.logo-nav-container .logo img {
  max-width: 100px;
}

.logo-nav-container .nav-list {
  color: #fff;
  display: flex;
  align-items: center;
  position: relative;
}
.nav-list li a {
  transition: all 0.15s linear;
  color: #fff;
}
.nav-list li:hover a {
  color: #00b1ff;
}

.navbar {
  position: fixed;
  width: 100%;
  transition: all 0.15s ease-out;
  background: transparent;
  z-index: 10000;
  top: 0px;
}
.navbar.white-bg {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 20px 40px;
}
.navbar.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}
.navbar.white-bg a {
  color: #000 !important;
}

a.router-link-exact-active.router-link-active {
  color: #00b1ff !important;
  padding-bottom: 4px;
  border-bottom: solid 1px;
}
.logo-nav-container a.router-link-exact-active.router-link-active {
  border-bottom: none;
}
.drop_menu a.router-link-exact-active.router-link-active {
}
svg {
  margin-left: 0 !important;
}

.nav { display: flex; }

ul li a,
ul li a:after,
ul li a:before {
  transition: all 0.25s;
}
.nav-list.stroke li a {
  position: relative;
  padding: 0 0 4px 0;
}
.nav-list.stroke li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: #00b1ff;
  height: 1px;
}
.nav-list.stroke li a:hover:after {
  width: 100%;
}
.nav-list.stroke li.nav-get-started-li a:hover:after {
  width: 0% !important;
}

.nav-get-started-btn {
  border: 1px solid #00b1ff;
  background-color: #00b1ff;
  border-radius: 6px;
  color: #fff;
  padding: 8px 15px;
  font-size: 16px;
  transition: all 0.125s linear;
}
.nav-get-started-btn:hover {
  background-color: #fff;
  color: #00b1ff;
  border: 1px solid #00b1ff;
}

.display {
  display: block;
}

#mobile-nav-button {
  display: none;
  transition: all 0.25s ease-in-out;
}

.drop a {
  cursor: pointer;
}
.nav .drop_menu {
  position: absolute;
  box-shadow: none;
  padding: 10px 0 10px 20px;
  width: 160px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 20px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 20px;
  transition: all 0.15s linear;
}
.nav .drop_menu li {
  color: #00b1ff;
  margin: 5px 0;
}
.nav .drop_menu a {
  width: 100%;
  padding: 20px;
  margin: 0;
  color: #00b1ff;
}

@media (max-width: 1300px) {
  .logo-nav-container {
    justify-content: space-between;
    margin-top: 20px;
  }
  li {
    margin: 0 10px;
  }
  .nav-list.stroke li a {
    font-size: 16px;
  }
}

@media (max-width: 860px) {
  .logo-nav-container {
    justify-content: space-between;
    margin: 10px auto;
  }

  #mobile-nav-button {
    display: block;
    transition: all 0.125s linear;
    position: relative;
    z-index: 1000;
    cursor: pointer;
  }

  .icon {
    display: inline-block;
    fill: currentColor;
    height: 1em;
    width: 1em;
    vertical-align: middle;
    position: relative;
    top: -0.0625em;
  }

  .icon-menu-toggle {
    width: 2em;
    height: 2em;
    top: -2px;
  }

  .svg-menu-toggle .line {
    opacity: 1;
    transform: rotate(0) translateY(0) translateX(0);
    transform-origin: 1em 1em;
    transition: transform 0.125s ease-in-out, opacity 0.25s ease-in-out;
  }
  .svg-menu-toggle .line-1 {
    transform-origin: 1em 2.5em;
  }
  .svg-menu-toggle .line-3 {
    transform-origin: 1em 4.5em;
  }

  .menu-toggle.opened .svg-menu-toggle .line-1 {
    transform: rotate(48deg) translateY(0) translateX(-0.5em);
  }
  .menu-toggle.opened .svg-menu-toggle .line-2 {
    opacity: 0;
  }
  .menu-toggle.opened .svg-menu-toggle .line-3 {
    transform: rotate(-50deg) translateY(0em) translateX(0.5em);
  }

  #nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
    position: absolute;
    top: 70px;
    left: 0;
    height: 0;
    width: 100%;
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 20px 40px;
    overflow: hidden;
    transition: all 0.25s linear;
  }

  #nav-menu.open {
    padding: 20px 0;
    height: 270px;
  }

  #nav-menu li {
    margin: 10px 0;
    padding-left: 20px;
  }

  .nav-list.stroke li a:hover:after {
    width: 0%;
  }

  #nav-menu li a {
    color: #000;
    font-size: 18px;
  }

  li.nav-get-started-li {
    display: none;
  }

  .nav {
    display: flex;
    align-items: center;
  }

  #mobile-nav-button svg {
    width: 25px;
  }

  .navbar.white-bg #mobile-nav-button svg path {
    fill: #000 !important;
  }

  .nav .drop_menu {
    background-color: #eee;
    z-index: 10;
    margin-top: 0px;
  }
}
/*li.drop > a:after {
  height: 0 !important;
  background: transparent;
}
li.drop:hover > a:after {
  border-bottom: solid 1px #00b1ff;
}*/
.router-link-active:after {
  height: 0 !important;
}

/*@media screen and (min-width: 860px) {
  li.drop:hover > ul,
  .drop_menu:hover {
    display: flex !important;
  }
  li.drop {
    padding-bottom: 25px;
  }
}*/

/*SAFARI NAV BG HACK*/
@media not all and (min-resolution:.001dpcm) { @media {

    .navbar { background-color:#fff !important; }
    .navbar a { color: #000 !important; }

    #mobile-nav-button svg { fill: #000 !important; }
}}
</style>
