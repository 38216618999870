<template>
  <div class="solutions-page-container">
    <nav-component></nav-component>

    <!-- <router-view></router-view> -->
    <footer-component></footer-component>
  </div>
</template>

<script>
  export default {
    data: function() {
      return {
        name: 'solutions'
      }
    }
  }
</script>

<style></style>
