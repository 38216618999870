<template>
  <transition name="slide">
    <div class="announcement-toast-container"  v-if="showToast">
      <div class="toast-close" @click="showToast = !showToast"></div>
      <p>Read about our updates regarding COVID-19 <router-link @click="showToast = !showToast" to="/covid-19-update">here</router-link></p>
    </div>
  </transition>
</template>


<script>
  export default {
    data() {
      return {
        showToast: false
      }
    },
    mounted() {
      this.showToast = true;
    }
  }
</script>


<style scoped>
  .announcement-toast-container {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 10px;
    box-sizing: border-box;
    background-color: #00b1ff;
    color: #fff;
    z-index: 100000;
    overflow: hidden;
  }

  .toast-close {
    position: absolute;
    right: 10px;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    border: 1px solid #fff;
    cursor: pointer;
    background-image: url('~@/assets/img/close.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 49%;
  }
  .announcement-toast-container p {
    font-size: 18px;
    line-height: 18px;
    margin: 10px 0;
  }
  .announcement-toast-container p a { text-decoration: underline; }

  .slide-enter-active { transition: all 0.5s 0.75s ease; }
  .slide-leave-active  { transition: all 0.5s 0s ease; }
  .slide-enter, .slide-leave-to {
    transform: translateY(100%);
    opacity: 0;
  }
</style>