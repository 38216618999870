<template>
  <transition name="fade" mode="out-in">
    <div class="contact-page-container">
      <nav-component></nav-component>
      <div class="contact-hero">
        <h2>LET'S CONNECT</h2>
      </div>
      <colours-bar></colours-bar>
      <div class="contact-form-container">
        <div class="contact-info">
          <h2>Toronto Headquarters</h2>
          <div class="address">
            <p class="charcoal">60 Columbia Way, 9th&nbsp;Floor<br>
            Markham, Ontario<br>
            Canada L3R 0C9<br>
            <a href="https://goo.gl/maps/mqa3a424LbNpdtik6" target="_blank"><span class="orange">>View Map</span></a></p>
            
            <p class="charcoal"><strong class="charcoal">General</strong><br>
            <a href="mailto:info@globalrewardsolutions.com">info@globalrewardsolutions.com</a></p>
            
            <p class="charcoal"><strong class="charcoal">Need customer support?</strong><br><br>
            Get fast, personalized help by logging into your program and placing a customer support ticket.</p>
            </p>
          </div>
          <!-- <h2>Connect With Us</h2> -->
          <div class="socials">
            <a href="https://www.facebook.com/Global-Reward-Solutions-1859166251009345/" target="_blank">
              <img src="@/assets/img/facebook-contact.png" alt="">
            </a>
            <a href="https://twitter.com/grs_rewards" target="_blank" rel="nofollow">
              <img src="@/assets/img/twitter-contact.png" alt="">
            </a>
            <a href="https://ca.linkedin.com/company/globalrewardsolutions" target="_blank" rel="nofollow">
              <img src="@/assets/img/linkedin-contact.png" alt="">
            </a>
          </div>
        </div>
        <div class="contact-form">
          <p class="learn-more-heading charcoal">Learn more about Global Reward Solutions</p>
          <p class="charcoal">Want to discover which of our solutions can help grow your business? Want to join our supplier network? Simply complete this form and we will be in touch.</p>

          <div id="hubspotForm"></div>
          
        </div>
      </div>
      <div class="our-offices">
        <h2>Our Global Offices</h2>
        <div class="addresses">
          <div class="row">
            <div class="address">
              <h3>Amman</h3>
              <p class="charcoal">Regus Jordan<br>
              Queen Zain Al-Sharaf Street<br>
              Amman Gate Building, 5th Floor<br>
              Amman, Jordan<br>
              <a href="https://goo.gl/maps/Sn2MbNNkrV8zS9sP7" target="_blank"><span class="orange">>View Map</span></a></p>
            </div>
            <div class="address">
              <h3>Chennai</h3>
              <p class="charcoal">86 Shree Niketan Brevery Road,<br>
              Shenoy Nagar, Chennai<br>
              Tamil Nadu 600030<br>
              India<br>
              <a href="https://goo.gl/maps/FHdtMPM5xCbSzq137" target="_blank"><span class="orange">>View Map</span></a></p>
            </div>
            <div class="address">
              <h3>London</h3>
              <p class="charcoal">33 Queen Street<br>
              London, EC4R 1BR<br>
              United Kingdom<br>
              <a href="https://goo.gl/maps/PzEHDUxgAzEZQtYh6" target="_blank"><span class="orange">>View Map</span></a></p>
            </div>
          </div>
          <div class="row">
            <div class="address">
              <h3>Manila</h3>
              <p class="charcoal">Two E-com Center, Mall Of Asia Complex,<br>
              Pasay City, 1300<br>
              Manila, Philippines<br>
              <a href="https://goo.gl/maps/ChdE45HyZDwjLrKd8" target="_blank"><span class="orange">>View Map</span></a></p>
            </div>
            <div class="address">
              <h3>Melbourne</h3>
              <p class="charcoal">Level 27<br>
              101 Collins Street<br>
              Melbourne VIC 3000<br>
              Australia<br>
              <a href="https://goo.gl/maps/Ck4dfsGaYHQVxYAe9" target="_blank"><span class="orange">>View Map</span></a></p>
            </div>
            <div class="address">
              <h3>Mexico City</h3>
              <p class="charcoal">Carretera Rio Hondo Huixquilucan KM. 14<br>
              Col. San Bartolomé<br>
              Huixquilucan, Estado de Mexico<br>
              México, 52770<br>
              <a href="https://goo.gl/maps/NqLkVane9WUc4Rts9" target="_blank"><span class="orange">>View Map</span></a></p>
            </div>
          </div>
          <div class="row">
            <div class="address">
              <h3>Sacramento</h3>
              <p class="charcoal">4725 Marlborough Way<br>
              Carmichael, California<br>
              USA 95608<br>
              <a href="https://goo.gl/maps/PA2q64r3UGXhj9fW9" target="_blank"><span class="orange">>View Map</span></a></p>
            </div>
            <div class="address">
              <h3>Santiago</h3>
              <p class="charcoal">Av. Juan XXIII 6700,<br>
              Depto 102<br>
              Vitacura, Chile<br>
              <a href="https://goo.gl/maps/FVwH1mqXAVqP8VGa8" target="_blank"><span class="orange">>View Map</span></a></p>
            </div>
            <div class="address">
              <h3>São Paulo</h3>
              <p class="charcoal">Avenida Brigadeiro Faria Lima,<br>
              1485, 1 andar, Torre Norte<br>
              São Paulo, SP<br>
              04578-002<br>
              Brazil<br>
              <a href="https://goo.gl/maps/7p1n88nio4jiiFB2A" target="_blank"><span class="orange">>View Map</span></a></p>
            </div>
          </div>
          <div class="row">
            <div class="address">
              <h3>Shanghai</h3>
              <p class="charcoal">No 324-602<br>
              Tai Bo Hua Yuan Section 2,<br>
              Meicun Town, New District,<br>
              Wuxi, Jiangsu, China<br>
              <a href="https://goo.gl/maps/Rx1iAFSRjsy9Wjqo8" target="_blank"><span class="orange">>View Map</span></a></p>
            </div>
            <div class="address">
              <h3>Sydney</h3>
              <p class="charcoal">Level 2, 441 Kent Street<br>
              Sydney NSW 2000<br>
              Australia<br>
              <a href="https://goo.gl/maps/p68H93Z7dxbqApPN6" target="_blank"><span class="orange">>View Map</span></a></p>
            </div>
            <div class="address">
              <h3>Taipei</h3>
              <p class="charcoal">Rm 8 5F, No. 182 Nanshan Rd,<br>
              Zhonghe District,<br>
              New Taipei City,<br>
              23560 Taiwan<br>
              <a href="https://goo.gl/maps/Cx9BBEiakaZZWiRaA" target="_blank"><span class="orange">>View Map</span></a></p>
            </div>
          </div>
        </div>
      </div>
      <footer-component></footer-component>
    </div>
  </transition>
</template>

<script>
  export default {
    data: function () {
      return {
        name: 'contact'
      }
    },
    mounted () {
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);

      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "9304889",
            formId: "a90006be-064a-43f9-be3f-e48e4b9467b4",
            target: "#hubspotForm"
          })
        }
      })
    }
  }
</script>

<style lang="scss">

  /*HEADER/HERO*/
  .contact-hero {
    background-image: url(../assets/img/contact-hero.jpg);
    background-position: center;
    -webkit-background-size: contain;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact-hero h2 {
    color: #fff;
    padding-top: 35px;
    font-size: 26px;
    letter-spacing: 6px;
    font-weight: 100;
  }

  /*CONTACT FORM*/
  .contact-form-container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    padding: 60px 0;
    flex-direction: row;
  }

  .contact-info { width: 40%; }
  .contact-info .address p a {
    color: inherit;
    transition: all 0.125s linear;
    display: block;
    padding-top: 15px;
  }
  .contact-info .address p a:hover { color: #00B1FF; }
  .contact-info h2 {
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 30px;
  }

  span.orange {
    color: #ff6b00;
    cursor: pointer;
  }

  .socials img {
    max-width: 35px;
    padding-right: 10px;
  }

  /*NEW HUBSPOT*/
  
  ::-webkit-input-placeholder { opacity: 1; }
  ::-moz-placeholder { opacity: 1; }

  p.learn-more-heading {
    font-size: 34px;
    font-weight: bold;
    margin: 0;
  }

  .contact-form {
    width: 60%;
    display: flex;
    flex-direction: column;

    #hubspotForm { margin-top: 30px; }

    form { width: 100% !important; }

    fieldset { max-width: unset !important; }
  }

  .hs-form-field {
    position: relative;
    margin-bottom: 30px;

    .hs-input {
      width: 100% !important;
      background-color: #fff;
    }

    input {
      width: 100%;
      box-sizing: border-box;
      
      &.hs-input.invalid.error { border: 1px solid #FD0000 !important; }
    }
        
    .hs-error-msgs {
      padding: 0 0 0 10px;
      margin: 0;
      list-style: none;
      position: absolute;
      top: 50px;
      
      li { 
        font-size: 12px;

        .hs-error-msg { color: #FD0000; }
      }
    }
  }

  .hs_error_rollup ul {
    margin: 0 0 20px 0;
    padding: 0 0 0 10px;
    list-style: none;

    li label { color: #FD0000; }
  }

  .contact-form input,
  .contact-form textarea {
    border: 1px solid #D4D9E2;
    border-radius: 3px;
    padding: 15px;
    font-size: 14px;
    color: #596377;
    outline: 0;
    transition: all 0.2s linear;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    border: 1px solid #000;
  }
  .contact-form textarea {
    min-height: 200px;
    resize: none;
    width: 100%;
    box-sizing: border-box;
  }

  .contact-form select {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 8px), calc(100% - 15px) calc(1em + 8px), calc(100% - 2.5em) 1em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    height: 46px;
    border: 1px solid #D4D9E2;
    border-radius: 3px;
    padding: 15px;
    font-size: 14px;
    color: #596377 !important;
    outline: 0;
  }

  #hsForm_a90006be-064a-43f9-be3f-e48e4b9467b4 select option,
  .contact-form select option { color: #596377 !important; }

  .hs-button.primary.large {
    width: fit-content;
    width: -moz-fit-content;
    text-align: center;
    color: #fff;
    background-color: #00B1FF;
    background-image: none !important;
    padding: 10px 20px;
    font-size: 18px !important;
    transition: 0.25s;
    border: 2px solid #00B1FF;
    position: relative;
    border-radius: 10px;
    margin: 0;
    cursor: pointer;
    text-transform: uppercase;
    font-family: mr-eaves-xl-modern, sans-serif;

    &:hover {
      background-color: #fff;
      color: #00B1FF;
    }
  }

  /*OFFICE LOCATIONS/ADDRESSES*/
  .our-offices {
    width: 100%;
    padding: 60px 0;
    background-color: #eeeeee;
    text-align: center;
  }

  .addresses {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .address {
    text-align: left;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .address h3 {
    font-size: 20px;
    font-weight: 200;
    color: #636466;
    margin: 0 0 4px 0;
  }
  .address p {
    margin-top: 8px;
    line-height: 18px;
  }

  @-moz-document url-prefix() { 
    .contact-hero h2 { font-weight: 300 !important; }
  }

  @media (max-width: 800px) {
    .contact-form-container { flex-direction: column; }

    .contact-page-container .our-offices h2 { font-size: 40px; }

    .contact-info {
      width: 100%;
      margin-bottom: 30px;
    }
    .contact-form { width: 100%; }

    .addresses .row { flex-direction: column; }
    .addresses .row .address { width: 60%; }
  }
</style>
