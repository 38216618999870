import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import WhyGRS from './views/WhyGRS.vue'
import Solutions from './views/Solutions.vue'
import Resources from './views/Resources.vue'
import GlobalSelection from './views/GlobalSelection.vue'
import Contact from './views/Contact.vue'
import About from './views/About.vue'
import RewardCloud from './views/RewardCloud.vue'
import GlobalSavings from './views/GlobalSavings.vue'
import GiftCodes from './views/GiftCodes.vue'
import Blog from './views/Blog.vue'
import BlogPost from './views/BlogPost.vue'

import BecomeAVendor from './views/BecomeAVendor.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'Global Reward Solutions | Reward & Loyalty Program Management',
        metaTags: [
          {
            name: 'description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          },
          {
            property: 'og:description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          }
        ]
      }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/About.vue'),
      meta: {
        title: 'About | Global Reward Solutions',
        metaTags: [
          {
            name: 'description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          },
          {
            property: 'og:description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          }
        ]
      }
    },
    {
      path: '/why-grs',
      name: 'whygrs',
      component: () => import('./views/WhyGRS.vue'),
      meta: {
        title: 'Why GRS and Gcodes | Global Reward Solutions',
        metaTags: [
          {
            name: 'description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          },
          {
            property: 'og:description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          }
        ]
      }
    },
    {
      path: '/blog',
      name: 'blog',
      component: () => import('./views/Blog.vue'),
      meta: {
        title: 'Blog | Global Reward Solutions',
        metaTags: [
          {
            name: 'description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          },
          {
            property: 'og:description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          }
        ]
      }
    },
    {
      path: '/blog/:slug',
      name: 'blog-post',
      component: () => import('./views/BlogPost.vue')
    },
    {
      path: '/solutions/reward-cloud',
      name: 'reward-cloud',
      component: () => import('./views/RewardCloud.vue'),
      meta: {
        title: 'Online Rewards Management Platform | GRS Reward Cloud',
        metaTags: [
          {
            name: 'description',
            content: 'Over 10 million of the world’s coolest rewards are now available for your program. Reward Cloud delivers merchandise to 185 countries. Contact Us. We enable virtual rewards such as event tickets, retail & prepaid gift cards, mobile top-up & online travel.'
          },
          {
            property: 'og:description',
            content: 'Over 10 million of the world’s coolest rewards are now available for your program. Reward Cloud delivers merchandise to 185 countries. Contact Us. We enable virtual rewards such as event tickets, retail & prepaid gift cards, mobile top-up & online travel.'
          }
        ]
      }
    },
    {
      path: '/solutions/global-savings',
      name: 'global-savings',
      component: () => import ('./views/GlobalSavings.vue'),
      meta: {
        title: 'Online & Digital Gifts System | GRS Global Savings',
        metaTags: [
          {
            name: 'description',
            content: 'Choose from hundreds of thousands of specially priced offers with exclusive savings off the top brand name merchandise, dining, travel, mobile top-up, gift cards, entertainment, and much more! This is the power of Global Savings. Contact Us'
          },
          {
            property: 'og:description',
            content: 'Choose from hundreds of thousands of specially priced offers with exclusive savings off the top brand name merchandise, dining, travel, mobile top-up, gift cards, entertainment, and much more! This is the power of Global Savings. Contact Us'
          }
        ]
      }
    },
    {
      path: '/solutions/gift-codes',
      name: 'gift-codes',
      component: () => import ('./views/GiftCodes.vue'),
      meta: {
        title: 'Virtual Rewards, Digital Gift Codes & Cards | GRS Global Selection',
        metaTags: [
          {
            name: 'description',
            content: 'Say farewell to the complexity of reward management with the speed and simplicity of Gift Codes. The best reward to boost loyalty, recognize performance, and engage employees. Contact Us'
          },
          {
            property: 'og:description',
            content: 'Say farewell to the complexity of reward management with the speed and simplicity of Gift Codes. The best reward to boost loyalty, recognize performance, and engage employees. Contact Us'
          }
        ]
      }
    },
    {
      path: '/global-selection',
      name: 'global-selection',
      component: () => import('./views/GlobalSelection.vue'),
      meta: {
        title: 'Employee, Customer & Loyalty Rewards | Global Reward Solutions',
        metaTags: [
          {
            name: 'description',
            content: 'Discover our amazing reward selection, with 10M+ rewards worldwide to drive your recognition, sales, customer loyalty,  employee engagement programs. Contact Us'
          },
          {
            property: 'og:description',
            content: 'Discover our amazing reward selection, with 10M+ rewards worldwide to drive your recognition, sales, customer loyalty,  employee engagement programs. Contact Us'
          }
        ]
      }
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('./views/Contact.vue'),
      meta: {
        title: 'Contact | Global Reward Solutions',
        metaTags: [
          {
            name: 'description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          },
          {
            property: 'og:description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          }
        ]
      }
    },
    {
      path: '/resources',
      name: 'resources',
      component: () => import('./views/Resources.vue')
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import('./views/PrivacyPolicy.vue'),
      meta: {
        title: 'Privacy | Global Reward Solutions',
        metaTags: [
          {
            name: 'description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          },
          {
            property: 'og:description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          }
        ]
      }
    },
    {
      path: '/accessibility-policy',
      name: 'accessibility-policy',
      component: () => import('./views/AccessibilityPolicy.vue'),
      meta: {
        title: 'Accessibility Policy | Global Reward Solutions',
        metaTags: [
          {
            name: 'description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          },
          {
            property: 'og:description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          }
        ]
      }
    },
    {
      path: '/covid-19-update',
      name: 'covid-19-update',
      component: () => import('./views/Covid19Update.vue'),
      meta: {
        title: 'COVID-19 Update | Global Reward Solutions',
        metaTags: [
          {
            name: 'description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          },
          {
            property: 'og:description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          }
        ]
      }
    },
    {
      path: '/become-a-vendor',
      name: 'become-a-vendor',
      component: () => import('./views/BecomeAVendor.vue'),
      meta: {
        title: 'Become A Vendor | Global Reward Solutions',
        metaTags: [
          {
            name: 'description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          },
          {
            property: 'og:description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          }
        ]
      }
    },
    {
      path: '/become-a-vendor-2',
      name: 'become-a-vendor-2',
      component: () => import('./views/BecomeAVendorTwo.vue'),
      meta: {
        title: 'Become A Vendor 2 | Global Reward Solutions',
        metaTags: [
          {
            name: 'description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          },
          {
            property: 'og:description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          }
        ]
      }
    },
    {
      path: '/become-a-vendor-3',
      name: 'become-a-vendor-3',
      component: () => import('./views/BecomeAVendorThree.vue'),
      meta: {
        title: 'Become A Vendor 3 | Global Reward Solutions',
        metaTags: [
          {
            name: 'description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          },
          {
            property: 'og:description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          }
        ]
      }
    },
    {
      path: '/become-a-vendor-4',
      name: 'become-a-vendor-4',
      component: () => import('./views/BecomeAVendorFour.vue'),
      meta: {
        title: 'Become A Vendor 4 | Global Reward Solutions',
        metaTags: [
          {
            name: 'description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          },
          {
            property: 'og:description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          }
        ]
      }
    },
    {
      path: '/vendor-application',
      name: 'vendor-application',
      component: () => import('./views/VendorApplication.vue'),
      meta: {
        title: 'Vendor Application | Global Reward Solutions',
        metaTags: [
          {
            name: 'description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          },
          {
            property: 'og:description',
            content: 'Global Reward Solutions is a cloud-based reward management system with local and global fulfillment capabilities for recognition, sales, customer loyalty,  employee engagement programs, marketing campaigns and contests. We source rewards from hundreds of suppliers around the globe. Contact Us'
          }
        ]
      }
    },
    {
      path : '*',
      redirect: '/'
    } 
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return {x: 0, y: 0}
    }
  }
})


