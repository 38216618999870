<template>
  <div class="footer-container">
    <div class="footer-top">
      <h2>Start connecting to the biggest Reward Cloud on the planet.</h2>
      <router-link to="/contact">
        <button class="footer-get-started">LET'S GET STARTED</button>
      </router-link>
    </div>
    <colours-bar></colours-bar>
    <div class="footer-bottom">
      <div class="sitemap">
        <div class="footer-logo">
          <svg-logo></svg-logo>
        </div>
        <div class="footer-section footer-company">
          <p><strong>COMPANY</strong></p>
          <router-link to="/global-selection"><p>Global Selection</p></router-link>
          <router-link to="/why-grs"><p>Why GRS</p></router-link>
          <router-link to="/about"><p>About</p></router-link>
          <a href="https://www.fitzii.com/fitzii_widget/271?key=bn7x0ss" target="_blank"><p>Careers</p></a>
        </div>
        <div class="footer-section footer-solutions">
          <p><strong>SOLUTIONS</strong></p>
          <router-link to="/solutions/reward-cloud"><p>Reward Cloud</p></router-link>
          <router-link to="/solutions/global-savings"><p>Global Savings</p></router-link>
          <router-link to="/solutions/gift-codes"><p>Gift Codes</p></router-link>
        </div>
        <div class="footer-section footer-connect">
          <p><strong>CONNECT WITH US</strong></p>
          <router-link to="/contact"><p>Book a Demo</p></router-link>
          <router-link to="/blog"><p>Blog</p></router-link>
          <router-link to="/contact"><p>Contact</p></router-link>
        </div>
        <div class="footer-section footer-socials">
          <a href="https://www.facebook.com/Global-Reward-Solutions-1859166251009345/" target="_blank"><img src="@/assets/img/facebook.png" alt="" rel="nofollow"></a>
          <a href="https://twitter.com/grs_rewards" target="_blank" rel="nofollow"><img class="twitter" src="@/assets/img/twitter.png" alt=""></a>
          <a href="https://ca.linkedin.com/company/globalrewardsolutions" target="_blank" rel="nofollow"><img src="@/assets/img/linkedin.png" alt=""></a>
        </div>
      </div>
      <div class="copyright-terms">
        <p>©2019 Global Reward Solutions. All rights reserved.</p>
        <div class="privacy">
          <!-- Add rel="nofollow" tags to these once they're built out -->
          <p><router-link to="/accessibility-policy">Accessibility Policy</router-link>&nbsp;&nbsp;|&nbsp;&nbsp;<router-link to="/privacy-policy">Privacy Policy</router-link>&nbsp;&nbsp;|&nbsp;&nbsp;<router-link to="/covid-19-update">COVID-19 Update</router-link></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style>
  .footer-container { width: 100%; }

  .footer-top {
    background-image: url(../assets/img/footer-mtns.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 100px;
  }
  .footer-top h2 {
    margin-bottom: 40px;
    font-weight: 300;
    color: #fff;
  }

  button.footer-get-started {
    border: 1px solid #fff;
    background-color: transparent;
    border-radius: 6px;
    color: #fff;
    padding: 8px 15px;
    font-size: 16px;
    margin-bottom: 80px;
    transition: all 0.125s linear;
  }
  button.footer-get-started:hover {
    background-color: #fff;
    color: #222;
  }

  .footer-bottom {
    background-color: #004a80;
    height: auto;
    padding: 60px 0px;
  }

  .sitemap {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    padding-bottom: 40px;
  }
  .sitemap p { font-weight: 300; }
  .sitemap div p:first-of-type { margin-top: 0 !important; }

  .footer-section { text-align: left; }

  .footer-socials {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .footer-socials img { width: 28px; }
  .footer-socials img.twitter { margin: 0 8px; }
  .footer-bottom .copyright-terms {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    border-top: 1px solid #fff;
  }
  .footer-bottom .copyright-terms p { font-weight: 300; }

  @media ( max-width: 800px ) {
    .footer-top {
      padding: 60px 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .footer-top h2 {
      font-size: 30px;
      line-height: 35px;
      font-weight: 200;
    }
    .footer-top button { margin: 0; }

    .sitemap {
      flex-direction: row;
      flex-wrap: wrap;
    }
    .sitemap .footer-logo {
      width: 100%;
      margin-bottom: 40px;
    }
    .sitemap .footer-section {
      width: 50%;
      margin-bottom: 10px;
    }
     .footer-section.footer-connect{ margin-bottom: 40px; }
    .sitemap .footer-section p { margin: 0; }

    .footer-section.footer-socials {
      width: 100%;
      margin-bottom: 0;
    }

    .footer-bottom .copyright-terms { flex-direction: column; }
    .copyright-terms p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
</style>
