<template>
  <div class="home-page-container">
    <AnnouncementToast />
    <nav-component></nav-component>
    <div class="homepage-slider">
      <carousel
        :nav="false"
        :items="1"
        :autoplay="true"
        :loop="true"
        :autoplayHoverPause="true"
        :responsive="{0:{items:1,nav:false,autoplay:false,mouseDrag:false,touchDrag:false,pullDrag:false},800:{items:1,autoplay:true}}"
      >
        <div class="slide two-new">
          <div class="slide-group">
            <div class="copy-side">
              <h2>
                Shopping made
                <br />faster, rewards
                <br />made easier
              </h2>
              <p>Introducing an all-new personalized Storefront experience powered by our revolutionary Rewards AI.</p>
              <div class="learn-more">
                <button class="learn-more-btn charcoal-btn" @click="$router.push('/why-grs')">CLICK TO LEARN MORE<br>ABOUT OUR NEW REWARDS AI</button>
              </div>
            </div>
            <div class="img-side">
              <img src="@/assets/img/macbook.png" alt="">
            </div>
          </div>
        </div>
        <div class="slide six">
          <div class="slide-group">
            <div class="copy-side">
              <h2>
                Create a Climate
                <br>for Change
              </h2>
              <p>For every transaction, we’ll fund the planting of five trees. In fact, we’re planting 5 million trees in the next 12 months as part of our Evergrow global climate change challenge.</p>
              <div class="learn-more">
                <router-link :to="{ path: '/why-grs', hash: '#evergrow-section' }">
                  <button class="learn-more-btn charcoal-btn">LEARN MORE</button>
                </router-link>
              </div>
            </div>
            <div class="img-side tree-slide-img">
              <img src="@/assets/img/tree-slide.png" alt="">
            </div>
          </div>
        </div>
        <div class="slide one">
          <h2>
            The World’s Leading Cloud&#x2011;Based
            <br />Reward Management Platform
          </h2>
          <img src="@/assets/img/cloud-icon.png" alt class="cloud-icon" />
          <HomepageIcons class="slider-icons" />
          <div class="learn-more">
            <router-link to="/solutions/reward-cloud">
              <button class="learn-more-btn">LEARN MORE</button>
            </router-link>
          </div>
        </div>
        <div class="slide two">
          <div class="slide-group">
            <h2>
              Discover Our
              <br />Global Selection
              <br />of Rewards
            </h2>
            <p>Learn more about what we offer worldwide.</p>
            <div class="learn-more">
              <router-link to="/global-selection">
                <button class="learn-more-btn">LEARN MORE</button>
              </router-link>
            </div>
          </div>
        </div>
        <!-- <div class="slide three">
          <div class="slide-group">
            <h2>
              Instant Financing for
              <br />Your Rewards
            </h2>
            <p>Our financing option is the first of its kind and allows you to finance rewards such as an Apple Watch through recurring payments by points, credit or even physical activity. Other payment options such as Apple Pay and PayPal are available as well.</p>
            <div class="pay-icons-holder">
              <img src="@/assets/img/apple-pay.png" alt class="pay-icon" />
              <img src="@/assets/img/paypal.png" alt class="pay-icon" />
            </div>
            <div class="learn-more">
              <router-link to="/why-grs">
                <button class="learn-more-btn">LEARN MORE</button>
              </router-link>
            </div>
          </div>
        </div> -->
        <div class="slide four">
          <div class="slide-group">
            <h2>
              Stunning Custom
              <br />Branded Storefronts
            </h2>
            <p>We are able to customize beautifully designed storefronts for partners such as Apple. The branded storefront allows our partners to present a recognizable look and feel to our members’ shopping experience.</p>
            <div class="learn-more">
              <router-link to="/solutions/reward-cloud">
                <button class="learn-more-btn">LEARN MORE</button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="slide five">
          <div class="slide-group">
            <h2>
              Available in the
              <br />Salesforce AppExchange
            </h2>
            <p>Reward Cloud, Global Savings and Gift Codes are all made available through AppExchange on Salesforce. Just add the GRS App within Salesforce and instantly reward your sales or channel partners for success.</p>
            <img src="@/assets/img/salesforce.png" alt class="salesforce-icon" />
            <div class="learn-more">
              <a
                href="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N30000000qD9wEAE"
                target="_blank"
              >
                <button class="learn-more-btn">LEARN MORE</button>
              </a>
            </div>
          </div>
        </div>
      </carousel>
    </div>
    <div class="homepage-mobile-hero">
      <div class="slide one">
        <h2>
          The World’s Leading Cloud&#x2011;Based
          <br />Reward Management Platform
        </h2>
        <img src="@/assets/img/cloud-icon.png" alt class="cloud-icon" />
        <HomepageIcons class="slider-icons" />
      </div>
    </div>
    <colours-bar class="home-top-colours"></colours-bar>
    
    <div class="one-stop">
      <h1 class="main-site-heading">Global Reward Solutions – Global Procurement &amp; Fulfillment</h1>
      <div class="circle-stats">
        <div class="circle faded-section with-transition-delay">
          <img src="@/assets/img/home-circle-1.png" alt />
        </div>
        <div class="circle middle faded-section">
          <img src="@/assets/img/home-circle-2.png" alt />
        </div>
        <div class="circle faded-section">
          <img src="@/assets/img/home-circle-3.png" alt />
        </div>
      </div>
      <p class="one-stop-copy charcoal">We enable loyalty, recognition, and performance companies everywhere to bring their customers or members the widest reward selection, with best-in-class customer service.</p>
      <div class="personalized-video">
        <transition name="fade" mode="out-in">
          <div class="img-transition" @click="playVideo()" v-if="!isHomepageVideoplayer">
          </div>
          <!-- <iframe v-else src="https://www.youtube.com/embed/uB02oVpgEMc?&autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

          <iframe v-else src="https://player.vimeo.com/video/457804043?color=ffffff&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>

        </transition>
      </div>
      <div class="learn-more">
        <router-link to="/why-grs">
          <button class="learn-more-btn charcoal-btn">LEARN MORE</button>
        </router-link>
      </div>
    </div>
    <div class="trusted">
      <div class="copy-container">
        <p
          class="charcoal"
        >Our platform is trusted to power the rewards programs of leading companies, including:</p>
      </div>
      <div class="logo-container">
        <img class="simple-fade with-transition-delay" src="@/assets/img/bell-logo.png" alt />
        <img class="simple-fade" src="@/assets/img/brother-logo.png" alt />
        <img class="simple-fade" src="@/assets/img/ford-logo.png" alt />
        <img class="simple-fade" src="@/assets/img/fujitsu-logo.png" alt />
        <img class="simple-fade" src="@/assets/img/novartis-logo.png" alt />
        <img class="simple-fade" src="@/assets/img/delta-logo.png" alt />
      </div>
    </div>
    <div class="offered">
      <h2>Rewards We Offer</h2>
      <HomepageIcons />
      <div class="learn-more">
        <router-link to="/global-selection">
          <button class="learn-more-btn">LEARN MORE</button>
        </router-link>
      </div>
    </div>
    <div class="our-solutions">
      <h2>Our Solutions</h2>
      <div class="three-col">
        <div class="box purp faded-section">
          <img src="@/assets/img/reward-cloud.png" alt />
          <p>Easily build a compelling rewards program, powered by our Reward Cloud of millions of high-value, premium brand products and digital&nbsp;rewards.</p>
          <router-link to="/solutions/reward-cloud">
            <button class="home-learn-more-btn">LEARN MORE</button>
          </router-link>
        </div>
        <div class="box green faded-section">
          <img src="@/assets/img/global-savings.png" alt />
          <p>Deliver instant membership discounts on thousands of premium brand products, gift cards, travel experiences and&nbsp;more.</p>
          <router-link to="/solutions/global-savings">
            <button class="home-learn-more-btn">LEARN MORE</button>
          </router-link>
        </div>
        <div class="box blue faded-section">
          <img src="@/assets/img/gift-codes.png" alt />
          <p>A fast, seamless and secure way to reward customers or members with virtual gift codes for instant savings across 185+ countries.</p>
          <router-link to="/solutions/gift-codes">
            <button class="home-learn-more-btn">LEARN MORE</button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="cust-support">
      <h2>Global Customer Support</h2>
      <div class="flex clock-box">
        <img src="@/assets/img/clock-icon.png" alt="">
        <div id="num-text" class="">
          <div>
            <span id="num-zing">98</span>
            <span>%</span>
          </div>
          <p class="simple-fade">
            of all order escalations are resolved
            <br />within one business day
          </p>
        </div>
      </div>
      <p class="one-stop-copy charcoal">Our customer support team is always ready for action 24/7 through our integrated ticket system.</p>
    </div>
    <div class="supplier">
      <div class="supplier-container">
        <div class="icon">
          <img src="@/assets/img/supplier-icon.png" alt />
        </div>
        <div class="supplier-copy">
          <h2>Become a Rewards Supplier</h2>
          <p>Want to expand your business globally? Become a Reward Cloud supplier and you can go global instantly. We can showcase your product to companies, and their customers in over 185 countries.</p>
          <router-link to="/contact">
            <button class="learn-more-btn">CONTACT US</button>
          </router-link>
        </div>
      </div>
    </div>
    <footer-component></footer-component>
  </div>
</template>
<script>
import carousel from 'vue-owl-carousel';
import HomepageIcons from '@/components/HomepageIcons.vue';
import AnnouncementToast from '@/components/AnnouncementToast.vue';
export default {
  name: 'home',
  components: {
    carousel,
    HomepageIcons,
    AnnouncementToast
  },
  data() {
    return {
      isHomepageVideoplayer: false,
      interval: []
    }
  },
  watch: {
    // $route (to, from) {
    //   this.clockConstructor()
    // }
  },
  methods: {
    fetchTreeCount: function () {
      const baseURI = 'https://api-ms.p2motivate.com/evergrow-trees/trees/funded'
      this.$http.get(baseURI)
      .then((result) => {
        this.interval = result.data
      })
    },
    playVideo() {
      this.isHomepageVideoplayer = !this.isHomepageVideoplayer;
    },
    // clockConstructor: function () {
    //   this.$nextTick().then(res => {
    //     function elementInViewport (el) {
    //       var top = el.offsetTop
    //       var left = el.offsetLeft
    //       var width = el.offsetWidth
    //       var height = el.offsetHeight
    //       while (el.offsetParent) {
    //         el = el.offsetParent
    //         top += el.offsetTop
    //         left += el.offsetLeft
    //       }
    //       return (
    //         top < window.pageYOffset + window.innerHeight &&
    //         left < window.pageXOffset + window.innerWidth &&
    //         top + height > window.pageYOffset &&
    //         left + width > window.pageXOffset
    //       )
    //     }
    //     // request animation frame
    //     window.requestAnimationFrame =
    //       window.requestAnimationFrame ||
    //       window.mozRequestAnimationFrame ||
    //       window.webkitRequestAnimationFrame ||
    //       window.msRequestAnimationFrame ||
    //       function (f) {
    //         return setTimeout(f, 100 / 60)
    //       };
    //
    //     window.cancelAnimationFrame =
    //       window.cancelAnimationFrame ||
    //       window.mozCancelAnimationFrame ||
    //       function (requestID) {
    //         clearTimeout(requestID)
    //       }; // fall back
    //
    //     // Start of clock HTML5 canvas
    //     var canvas = document.getElementById('clock')
    //
    //     var ctx = canvas.getContext('2d')
    //     canvas.width = 240
    //     canvas.height = 240
    //     function inView () {
    //       // The meat and potatoes of
    //       var count = 0
    //       function draw (i) {
    //         // Begin by clearing canvas
    //         ctx.setTransform(1, 0, 0, 1, 0, 0)
    //         ctx.clearRect(0, 0, canvas.width, canvas.height)
    //         // Outer Circle
    //         ctx.beginPath()
    //         ctx.arc(
    //           canvas.width / 2,
    //           canvas.height / 2,
    //           (canvas.height - 10) / 2,
    //           0,
    //           2 * Math.PI
    //         )
    //         ctx.fillStyle = 'rgba(82, 177, 0, 1)';
    //         ctx.closePath()
    //         ctx.fill()
    //
    //         // count += 1;
    //         // //where number of paints => 133
    //         //   // Outer circle animation
    //         //   ctx.beginPath();
    //         //   ctx.fillStyle = "rgba(61, 133, 0, 1)";
    //         //       ctx.arc(canvas.width / 2, canvas.height / 2,(canvas.height - 10 ) / 2, -Math.PI/2, (count/133)*(2* Math.PI) - Math.PI/2 );
    //         //   ctx.fill();
    //         //   ctx.closePath();
    //
    //         ctx.beginPath()
    //
    //         ctx.arc(
    //           canvas.width / 2,
    //           canvas.height / 2,
    //           (canvas.height - 70) / 2,
    //           0,
    //           2 * Math.PI
    //         )
    //         ctx.fillStyle = 'rgba(255,255,255,1)';
    //         ctx.fill()
    //         ctx.closePath()
    //         // end of outer circle
    //
    //         // begin clock
    //         var clockRadius = 50
    //         ctx.beginPath()
    //         ctx.arc(
    //           canvas.width / 2,
    //           canvas.height / 2 + 10,
    //           clockRadius,
    //           0,
    //           2 * Math.PI
    //         )
    //         ctx.strokeStyle = 'rgba(61, 133, 0, 1)';
    //         ctx.lineWidth = 4.5
    //         function button (i = 0) {
    //           if (i == 1) {
    //             ctx.rotate(Math.PI / 2)
    //
    //             ctx.strokeStyle = '#000';
    //           } else if (i == 2) {
    //             ctx.rotate(-Math.PI / 4)
    //           }
    //           ctx.moveTo(canvas.width / 2 - 10, canvas.height / 2 - 41)
    //           ctx.lineTo(canvas.width / 2 - 10, canvas.height / 2 - 60)
    //           ctx.lineTo(canvas.width / 2 + 10, canvas.height / 2 - 60)
    //           ctx.lineTo(canvas.width / 2 + 10, canvas.height / 2 - 41)
    //           ctx.stroke()
    //         }
    //         button()
    //
    //         ctx.closePath()
    //         ctx.beginPath()
    //         // rectangle 2
    //         ctx.closePath()
    //         ctx.rotate(Math.PI / 3.99)
    //
    //         ctx.translate(106, canvas.height / 2 - 123)
    //
    //         ctx.strokeRect(0, 0, 20, 20)
    //
    //         ctx.setTransform(1, 0, 0, 1, 0, 0)
    //         // Rectangle 3
    //         ctx.closePath()
    //         ctx.beginPath()
    //         // rectangle 2
    //         ctx.closePath()
    //         ctx.rotate(Math.PI / 3.9)
    //
    //         ctx.translate(165, canvas.height / 2 - 187)
    //
    //         ctx.strokeRect(0, 0, 20, 20)
    //
    //         ctx.setTransform(1, 0, 0, 1, 0, 0)
    //         // Markings/Numerals/inside of clock- Adapted from the clock developed by  Neil Wallis
    //         ctx.closePath()
    //
    //         ctx.beginPath()
    //
    //         ctx.arc(
    //           canvas.width / 2,
    //           canvas.height / 2 + 10,
    //           3.5,
    //           0,
    //           2 * Math.PI
    //         )
    //
    //         ctx.translate(canvas.width / 2, canvas.height / 2 + 10)
    //         ctx.fillStyle = 'rgba(61, 133, 0, 1)';
    //         ctx.fill()
    //         ctx.closePath()
    //         let sx, sy, ang, sang, cang, ex, ey, nx, ny
    //         for (let i = 1; i <= 60; i++) {
    //           ang = (Math.PI / 30) * i
    //           sang = Math.sin(ang)
    //           cang = Math.cos(ang)
    //           if (i % 5 == 0) {
    //             sx = sang * (clockRadius - 10)
    //             sy = cang * -(clockRadius - 10)
    //             ex = sang * (clockRadius - 21)
    //             ey = cang * -(clockRadius - 21)
    //             nx = sang * 80
    //             ny = cang * -80
    //             ctx.beginPath()
    //             ctx.moveTo(sx, sy)
    //             ctx.lineTo(ex, ey)
    //             ctx.stroke()
    //           }
    //         }
    //
    //         // Clocks hand
    //         ctx.closePath()
    //         ctx.beginPath()
    //         ctx.fillStyle = 'rgba(61, 133, 0, 1)';
    //         ctx.rotate(Math.PI * i)
    //         ctx.fillRect(-2, 0, 4, -40)
    //         ctx.closePath()
    //       } // Draw
    //
    //       var speed = 0.04
    //       var iter = speed
    //       var j = 0
    //
    //       function clockAnimation (j) {
    //         draw(iter)
    //         iter += speed
    //         j += 1
    //         if (iter > 1 && speed > 0.001) {
    //           speed = speed * 0.962
    //         }
    //
    //         if (iter < 2) window.requestAnimationFrame(clockAnimation)
    //       }
    //       clockAnimation(iter)
    //
    //       // Start of Incrementing Number function - based on example from the Easing Library
    //       function numberAnimation () {
    //         function easeOut (n) {
    //           return n * (2 - n)
    //         }
    //         var stop = false
    //         var duration = 2500
    //         var start = null
    //         var end = null
    //
    //         function startAnim (timeStamp) {
    //           start = timeStamp
    //           end = start + duration
    //           incrementNums(timeStamp)
    //         }
    //         let i = 0
    //         function incrementNums (now) {
    //           if (stop) return
    //           if (now - start >= duration) stop = true
    //           var p = Math.min((now - start) / duration, 0.98)
    //           var n = easeOut(p)
    //           if (n <= 98) {
    //             if (p > 0.85) {
    //             }
    //             document.getElementById('num-zing').innerHTML = Math.floor(
    //               Math.min(n * 100, 98)
    //             )
    //           }
    //           requestAnimationFrame(incrementNums)
    //         }
    //         requestAnimationFrame(startAnim)
    //       }
    //       numberAnimation()
    //     } // end of inView
    //
    //     window.addEventListener('scroll', checkStart)
    //     let hasRun = false
    //     function checkStart () {
    //       // check route
    //       if (
    //         res.$route.name == 'home' &&
    //         elementInViewport(document.querySelector('.flex')) &&
    //         !hasRun
    //       ) {
    //         document.getElementById('num-text').classList.add('fade-in')
    //         inView()
    //         hasRun = true
    //       }
    //     }
    //   }) // END TICK
    // }
  },
  created () {
    this.fetchTreeCount()
    this.interval = setInterval(() => this.fetchTreeCount(), 30000);
  },
}
</script>

<style>
@import url("../css/homepage.css");

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(82, 177, 0, 1);
}
#clock {
  padding-right: 20px;
}
#num-text p {
  margin-top: 0;
}
#num-text div {
  text-align: center;
  color: rgba(82, 177, 0, 1);
  font-weight: 800;
  font-style: normal;
  font-size: 150px;
  width: 250px;
  letter-spacing: -8px;
}

#num-text {
  padding-left: 20px;
}
#num-text span,
#num-text p {
  color: rgba(82, 177, 0, 1);
}
#num-text p {
  text-align: left;
  transition: opacity 1s;
  transition-delay: 1.2s;
}
#num-text p.out-of-sight {
  opacity: 0;
}

#num-text p.faded {
  opacity: 1;
}
</style>
