<template>
  <div id="app">
    <transition name="page-fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
<script>
export default {
  name: 'App',
  watch: {
    $route (to, from) {
      this.customJS()
    }
  },
  mounted () {
    this.customJS()
  },
  methods: {
    customJS: function () {
      this.$nextTick(() => {
        // check if element is visible by end-user
        function elementInViewport (el) {
          var top = el.offsetTop
          var left = el.offsetLeft
          var width = el.offsetWidth
          var height = el.offsetHeight
          while (el.offsetParent) {
            el = el.offsetParent
            top += el.offsetTop
            left += el.offsetLeft
          }
          return (
            top < window.pageYOffset + window.innerHeight &&
            left < window.pageXOffset + window.innerWidth &&
            top + height > window.pageYOffset &&
            left + width > window.pageXOffset
          )
        }
        // specified class for which effect is applied
        // Legend of Animation Types:
        //
        // 'faded-section' => Fade in and shift upwards
        // 'simple-fade' => Fade in
        // 'fade-left' => Fade in and shit right
        // where
        var animationTypes = [
          'faded-section',
          'simple-fade',
          'fade-left',
          'fade-right'
        ]
        for (let j = 0; j < animationTypes.length; j++) {
          const posts = document.getElementsByClassName(animationTypes[j])

          for (var i = 0; i < posts.length; i++) {
            if (
              elementInViewport(posts[i]) == !1 ||
              posts[i].classList.contains('in-view-trans')
            ) {
              // only add out-of-sight to elements outside of the view port
              // add it programmatically so content is still visible if JS fails on page
              posts[i].classList.add('out-of-sight')
            }
          }
        }

        // Master function for defining event handlers for CSS animations
        var animateHTML = function () {
          var elems
          var windowHeight

          // kicks off the animation
          function init () {
            elems = document.querySelectorAll('.out-of-sight')
            windowHeight = window.innerHeight
            addEventHandlers()
            checkPosition()
          }
          // Home for event handlers
          function addEventHandlers () {
            window.addEventListener('scroll', checkPosition)
            window.addEventListener('resize', init)
          }

          function checkPosition () {
            for (var i = 0; i < elems.length; i++) {
              var positionFromTop = elems[i].getBoundingClientRect().top

              // when element comes into view replace out of sight with faded -> triggering animation
              // Alternatively, this can be identified by the elementInViewport function defined above
              if (positionFromTop - windowHeight <= 0) {
                elems[i].classList.add('faded')
              }
            }
          }
          return {
            init: init
          }
        };
        animateHTML().init()
      }) // end of endtick
    }
  }
}
</script>
<!-- GLOBAL STYLES -->

<style lang="scss">
@import url("https://use.typekit.net/nfa8jzy.css");
/*@import url("https://cdn.jsdelivr.net/npm/animate.css@3.5.1");*/
body {
  overflow-x: hidden;
  margin: 0;
  background-color: #fff;
}

* {
  font-family: mr-eaves-xl-modern, sans-serif;
  color: #fff;
}
*:focus {
  outline: none !important;
}

h1 {
  font-weight: 700;
  font-style: normal;
  font-size: 74px;
  margin: 0;
}
h2 {
  font-weight: 300;
  font-style: normal;
  font-size: 40px;
  margin: 0 0 30px 0;
}
h3 {
}
h4 {
}
p {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #fff;
}

.charcoal {
  color: #636466;
}

span {
}
a {
  text-decoration: none;
}
button {
  cursor: pointer !important;
  transition: all 0.125s linear;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.32s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.autocomplete-wrapper input.autocomplete-input {
  color: #000 !important;
}
.post-container .post-body p {
  color: #4d4d4f;
}

.post-body p a {
  color: #4d4d4f !important;
  text-decoration: underline;
}
.post-body p,
.post-body p strong,
.post-body p em,
.post-body li strong,
.post-body h3 {
  color: #4d4d4f !important;
}
.post-body p img {
  width: 100% !important;
}
.post-body p a {
  cursor: pointer !important;
}

.post-body p sup {
  color: #4d4d4f !important;
  line-height: 0px !important;
  font-size: 12px !important;
}
.post-body p sub { color: #4d4d4f !important; }

.post-container.single-post i,
.post-container.single-post ul li {
  color: #4d4d4f !important;
}
.post-container.single-post ul li {
  font-family: mr-eaves-xl-modern, sans-serif;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
}

@media (max-width: 800px) {
  .post-body p img {
    width: 100% !important;
  }
  body {
    overflow-x: hidden !important;
  }
}
a {
  cursor: pointer;
}
/**************************************

  Styles for transitions.js

***************************************/

.faded-section.out-of-sight {
  transform: translateY(60px);
  opacity: 0;
}
.fade-left.out-of-sight {
  opacity: 0;
  transform: translateX(-60px);
}
.fade-right.out-of-sight {
  opacity: 0;
  transform: translateX(60px);
}

.simple-fade.out-of-sight {
  opacity: 0;
}
.simple-fade.faded {
  opacity: 1;
}
.simple-fade {
  transition-delay: 0.85s;
  transition: opacity 1s;
}

.faded-section.faded {
  -webkit-animation: fade-up 1s forwards;
  animation: fade-up 1s forwards;
  transform: 0;
  -webkit-transition: transform 1s;
  -o-transition: transform 1s;
  transition: transform 1s;
}

.fade-left.faded {
  -webkit-animation: fade-left 1s forwards;
  animation: fade-left 1s forwards;
  transform: 0;
  -webkit-transition: transform 1s;
  -o-transition: transform 1s;
  transition: transform 1s;
}
.fade-right.faded {
  -webkit-animation: fade-right 1s forwards;
  animation: fade-right 1s forwards;
  transform: 0;
  -webkit-transition: transform 1s;
  -o-transition: transform 1s;
  transition: transform 1s;
}
@for $i from 2 through 20 {
  .with-transition-delay ~ .faded:nth-child(#{$i}) {
    animation-delay: $i/7 - 0.1s;
  }
  .with-transition-delay ~ .simple-fade.faded:nth-child(#{$i}) {
    transition-delay: #{$i/10}s;
  }
}

@keyframes fade-up {
  0% {
    transform: translateY(60px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    transform: translateX(-60px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-right {
  0% {
    transform: translateX(60px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}



.page-fade-enter-active,
.page-fade-leave-active { transition: opacity .25s; }
.page-fade-enter,
.page-fade-leave-to { opacity: 0; }
</style>