<template>
  <div class="why-page-container">
    <nav-component></nav-component>
    <div class="why-grs-hero">
      <p class="top">WHY GRS&#63;</p>
      <h1>Simplified Rewards Worldwide</h1>
      <p class="bottom">With one API you can now procure rewards in one country…or one hundred countries.</p>
    </div>
    <colours-bar></colours-bar>
    <div class="why-body why-ai">
      <div class="why-section personalized-section">
        <div class="rewards-ai">
          <div class="ai-row">
            <div class="container">
              <img class="storefront-img fade-left in-view-trans" src="@/assets/img/storefront-ai.png" alt />
              <div class="copy">
                <h2>Personalized by a Revolutionary Rewards AI</h2>
                <p class="charcoal">Inspiring positive outcomes and creating true behaviour change requires motivational rewards that are personally meaningful. We use machine learning and neural net algorithms to find what your members will love. Enriched by patterns of the rewards a member has searched, clicked, or redeemed for, our Reward AI suggests new rewards that match their interests. The Personalized Storefront Reward AI truly personalizes the shopping experience, making it more engaging and rewarding for your members.</p>
                <div class="learn-more">
                  <button class="learn-more-btn charcoal-btn" @click="$router.push('/contact')">BOOK A DEMO</button>
                </div>
              </div>
            </div>
          </div>
          <div class="personalized-video">
            <transition name="fade" mode="out-in">
              <div class="img-transition" @click="playVideo()" v-if="!isVideoplayer">
              </div>
              <!-- <iframe v-else src="https://www.youtube.com/embed/uB02oVpgEMc?&autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
              <iframe v-else src="https://player.vimeo.com/video/457804043?color=ffffff&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </transition>
          </div>
          <div class="ai-row">
            <div class="container">
              <div class="copy latest-search">
                <h2>The Latest Search & Shop Interface Design</h2>
                <p class="charcoal">It’s a revolution in reward shopping design. Faster, easy to navigate and more intuitive, our new interface makes searching and redeeming more fun than ever. We’re launching today with FIVE new design features: Featured Brands, Faceted Browsing, Attribute Grouping, Featured Categories, and a new search mega menu that makes it even faster to breeze through the store and find exactly what you are seeking.</p>
                <div class="learn-more">
                  <button class="learn-more-btn charcoal-btn" @click="$router.push('/contact')">BOOK A DEMO</button>
                </div>
              </div>
              <img class="storefront-img fade-right in-view-trans" src="@/assets/img/macbook.png" alt />
            </div>
          </div>
          <div class="ai-row">
            <div class="container">
              <img class="storefront-img fade-left in-view-trans cards" src="@/assets/img/digital-unlock.png" alt />
              <div class="copy">
                <h2>Securely Unlock Any Digital or Prepaid Gift Card</h2>
                <p class="charcoal">With gift cards, virtual gift codes and virtual Visa and Mastercard prepaid debit cards quickly becoming popular reward options, the Personalized Storefront makes it easier to find the perfect reward from among hundreds of brands. Just pick a card, pick the denomination and click redeem. For virtual cards, members will receive an email with a link and verification code that will securely unlock their reward. It takes just seconds and protects your member’s privacy.</p>
                <div class="learn-more">
                  <button class="learn-more-btn charcoal-btn" @click="$router.push('/contact')">BOOK A DEMO</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="why-body trees" id="evergrow-section">
      <div class="why-section personalized-section">
        <div class="rewards-ai">
          <div class="ai-row">
            <div class="container">
              <div class="copy">
                <h2>Creating a climate for change.</h2>
                <p class="charcoal">As a company with millions of users across 185 countries, CarltonOne believes we have the global capability to make a significant climate change impact. We are a proud member of the UN Global Compact corporate responsibility initiative. For every merchandise and gift card transaction, we’re funding the planting of new native trees around the world with our Evergrow eco-action sustainability initiative. In partnership with Eden Reforestation, our funding helps local communities thrive as they plant and protect new forests and mangroves. And by using local vendor sourcing and delivery, we can deliver rewards faster with no extra duties or taxes, and with a more responsible environmental profile.</p>
               
              </div>
              <img class="storefront-img fade-right in-view-trans" src="@/assets/img/evergrow.png" alt />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="why-body">
      <div class="why-section">
        <div class="icon-container simple-fade">
          <img src="@/assets/img/why1.png" alt />
        </div>
        <div class="copy">
          <h2>Seamless Integration</h2>
          <p class="charcoal">We’ve engineered Rewards Cloud to be easy to connect with clear APIs and friendly onboarding support. And as your business grows, Rewards Cloud can scale to offer more features, more rewards, and even availability in regions around the globe.</p>
        </div>
      </div>
      <div class="why-section">
        <div class="icon-container simple-fade">
          <img src="@/assets/img/why2.png" alt />
        </div>
        <div class="copy">
          <h2>Painless Management</h2>
          <p class="charcoal">You come to work every day to grow your business, not wrangle rewards programs. That’s our expertise. We manage the fulfillment of over 10 million items, for thousands of companies, in 38 languages, 63 currencies and 185 countries… so you don’t have to. We’ve built Rewards Cloud to help efficiently engage, inspire and motivate your employees and customers, all while minimizing the time you need to spend worrying about it.</p>
        </div>
      </div>
      <div class="why-section">
        <div class="icon-container simple-fade">
          <img src="@/assets/img/why3.png" alt />
        </div>
        <div class="copy">
          <h2>Loyalty</h2>
          <p class="charcoal">Loyal, repeat customers help grow your business. Our GRS solutions increase customer loyalty by motivating them to spend points on the rewards they love. Our vast catalogue includes many low-point redemption items (like gift cards for as low as $5) — helping ensure that every customer gets an opportunity to experience the thrill of receiving a reward, and keeping your outstanding point liability low. By creating a positive shopping experience that keeps customers coming back, we help your keep those repeat consumers while attracting new ones.</p>
        </div>
      </div>
      <div class="why-section">
        <div class="icon-container simple-fade">
          <img src="@/assets/img/why4.png" alt />
        </div>
        <div class="copy">
          <h2>Procurement Engine</h2>
          <p class="charcoal">We are a global procurement company that always feels local. Based in North America, we have offices around the world including China, Australia, and the UK. Our skilled network of in-country procurement specialists purchase rewards locally, enabling faster delivery, and lower taxes and duties. We reduce procurement overhead by managing all of the processes and data, and have responsive 24/7 customer service to answer your questions and resolve any issues. To simplify currency details, we covert all currencies (currently 63) on the same day and at the current rate.</p>
        </div>
      </div>
      <div class="why-section">
        <div class="icon-container simple-fade">
          <img src="@/assets/img/why5.png" alt />
        </div>
        <div class="copy">
          <h2>Financing</h2>
          <p class="charcoal">Financing purchases within the loyalty space is a completely new option. Our variable recurring payment solution provides “instant financing” to programs. The Storefront user experience seamlessly integrates instant financing requirements, including the presentation of recurring payment amounts by payment term, the capture of the desired payment method, and the execution of a credit check. Our financing option is the first of its kind and allows you to finance with points or credit. We have many payment options available such as Apple Pay and PayPal.</p>
        </div>
      </div>
      <div class="why-section">
        <div class="icon-container simple-fade">
          <img src="@/assets/img/why6.png" alt />
        </div>
        <div class="copy">
          <h2>Global Tax Compliance</h2>
          <p class="charcoal">GRS offers complete tax transparency and compliance, giving you full access to all tax information necessary for seamless reward transactions.</p>
        </div>
      </div>
    </div>
    <div class="innovative-body">
      <h2>Innovative Technology that Works</h2>
      <div class="innovative-icons-container">
        <div class="row">
          <div class="innovative-icon simple-fade with-transition-delay">
            <div class="icon-holder dark-orange">
              <img src="@/assets/img/innovation1.png" alt />
            </div>
            <h3 class="charcoal">Global</h3>
            <p class="small charcoal">We give a new meaning to the word “international” by encompassing literally the entire planet in 185+ countries</p>
          </div>
          <div class="innovative-icon simple-fade">
            <div class="icon-holder sky-blue">
              <img src="@/assets/img/innovation2.png" alt />
            </div>
            <h3 class="charcoal">
              24/7 Customer
              <br />Service
            </h3>
            <p class="small charcoal">Our friendly and responsive support team is always happy to help solve your problems ASAP</p>
          </div>
          <div class="innovative-icon simple-fade">
            <div class="icon-holder pink">
              <img src="@/assets/img/innovation3.png" alt />
            </div>
            <h3 class="charcoal">
              Supplier
              <br />Updates
            </h3>
            <p class="small charcoal">Automated daily supplier updates help eliminate out of stocks, while maximizing member satisfaction</p>
          </div>
          <div class="innovative-icon simple-fade">
            <div class="icon-holder peach">
              <img src="@/assets/img/innovation4.png" alt />
            </div>
            <h3 class="charcoal">Order Tracking</h3>
            <p class="small charcoal">We provide real-time order tracking, so you can check your order at any time</p>
          </div>
        </div>
        <div class="row">
          <div class="innovative-icon with-transition-delay simple-fade">
            <div class="icon-holder purp">
              <img src="@/assets/img/innovation5.png" alt />
            </div>
            <h3 class="charcoal">GRS Dashboard</h3>
            <p class="small charcoal">Our GRS dashboard provides instant transparency on all performance metrics and rewards costs</p>
          </div>
          <div class="innovative-icon simple-fade">
            <div class="icon-holder pea-green">
              <img src="@/assets/img/innovation6.png" alt />
            </div>
            <h3 class="charcoal">
              Extraordinary
              <br />Selection
            </h3>
            <p class="small charcoal">An expansive inventory of over 20 million rewards is available so that you’re getting exactly what you want</p>
          </div>
          <div class="innovative-icon simple-fade">
            <div class="icon-holder hot-pink">
              <img src="@/assets/img/innovation7.png" alt />
            </div>
            <h3 class="charcoal">Storefront</h3>
            <p class="small charcoal">Our quick connect API enables you to launch customizable reward platforms by customer</p>
          </div>
          <div class="innovative-icon simple-fade">
            <div class="icon-holder lilac">
              <img src="@/assets/img/innovation8.png" alt />
            </div>
            <h3 class="charcoal">
              Global&nbsp;Currency
              <br />Management
            </h3>
            <p class="small charcoal">We process orders locally in local currency and consolidate all orders into a single currency of your choice</p>
          </div>
          <div class="innovative-icon simple-fade">
            <div class="icon-holder true-blue">
              <img src="@/assets/img/innovation9.png" alt />
            </div>
            <h3 class="charcoal">In-Country</h3>
            <p class="small charcoal">We strive to ensure a culturally relevant reward list by sourcing in-country reward offers while mitigating costly duties and taxes... this lets us ship rewards overnight</p>
          </div>
        </div>
      </div>
    </div>
    <div class="we-care">
      <h2>We Care About the World</h2>
      <div class="care-two-col faded-section">
        <div class="care-col one">
          <div class="care-icon">
            <img src="@/assets/img/ethics-icon.png" alt />
          </div>
          <div class="care-copy">
            <h2>Ethical Sourcing</h2>
            <p>GRS is committed to ensuring that all of our rewards are ethically sourced, in every country that we operate.</p>
          </div>
        </div>
        <div class="care-col two">
          <div class="care-icon">
            <img src="@/assets/img/cultural-icon.png" alt />
          </div>
          <div class="care-copy">
            <h2>Cultural Relevance</h2>
            <p>We have buyers based in each region who source local in-country products and services that are culturally relevant.</p>
          </div>
        </div>
      </div>
    </div>
    <footer-component></footer-component>
  </div>
</template>

<script>


export default {
  name: "whygrs",
  data() {
    return {
      isVideoplayer: false,
      interval: []
    };
  },
  methods: {
    playVideo() {
      this.isVideoplayer = !this.isVideoplayer;
    }, 
    fetchTreeCount: function () {
      const baseURI = 'https://api-ms.p2motivate.com/evergrow-trees/trees/funded'
      this.$http.get(baseURI)
      .then((result) => {
        this.interval = result.data
      })
    }
  }, 
  mounted() {
    this.$nextTick(() => {
      if(this.$route.hash) {
        const el = document.querySelector(this.$route.hash);
        el && el.scrollIntoView();
      }
    })
  },
  created () {
    this.fetchTreeCount()
    this.interval = setInterval(() => this.fetchTreeCount(), 30000);
  }
};
</script>

<style scoped>

.fade-enter,
.fade-leave-to { opacity: 0; }
.fade-enter-active,
.fade-leave-active { transition: 0.35s ease-in-out; }
/*HEADER/HERO*/
.why-grs-hero {
  background-image: url(../assets/img/why-grs-hero.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 100px 0;
  text-align: center;
}
.why-grs-hero p.top {
  font-size: 26px;
  letter-spacing: 6px;
  font-weight: 100;
  margin-bottom: 110px;
}
.why-grs-hero h1 {
  font-size: 60px;
  margin-bottom: 30px;
}

/*MAIN PAGE SECTION*/
.why-body.why-ai { padding: 0 !important; }
.why-body {
  background-color: #fff;
  padding: 80px 0;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.why-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 80px;
}

.why-section.personalized-section { margin-bottom: 0; }
.why-section.personalized-section .ai-row .container {
  width: 100%;
  height: auto;
  justify-content: space-between;
  margin: 120px 0;
}
.why-section.personalized-section .ai-row .container img { width: 45%; }
.why-section.personalized-section .ai-row .container .copy { width: 50%; }
.why-section.personalized-section .ai-row .container .copy p { width: 100%; }
.why-section.personalized-section .ai-row .container .copy.latest-search { margin-bottom: 60px; }


/*VIDEO CONTAINER*/

.personalized-video {
  margin: 120px 0;
  border-radius: 20px;
  max-height: 648px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  overflow: hidden;
  position: relative;
  width: 100%;
}
.personalized-video::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}
.personalized-video .img-transition{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #57b8e6;
  border-radius: 20px;
  height: 649px;

  background-image: url('../assets/img/storefront-video.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

}
.personalized-video .img-transition {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1420px;
  height: 100%;
}
/*.personalized-video iframe {
  border-radius: 20px;
  width: 100%;
  height: 649px;
}*/

.personalized-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1420px;
  height: 100%;
}


.why-body.trees {
  background-color: #EEE;
  width: 100%;
  max-width: none;
  padding: 40px 0;
}
.why-body.trees .why-section.personalized-section {
  width: 80%;
  margin-bottom: 0;
}
.why-body.trees .container { margin: 0 !important; }

.why-section .icon-container { margin-right: 60px; }
.why-section h2 { margin-bottom: 20px; }

.tree-counter-container {
  border-radius: 40px;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tree-counter-container .trees-count {
  font-size: 72px;
  color: #333333;
  margin-bottom: 14px;
}
.tree-counter-container p.trees-planted {
  font-size: 36px;
  color: #00A562;
  margin: 0;
  /*width: fit-content;*/
  text-align: center;
}

.innovative-body {
  background-color: #eeeeee;
  padding: 60px 0;
  text-align: center;
}

.innovative-icons-container {
  margin: 0 auto 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}
.innovative-icons-container .row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 60%;
  margin-bottom: 30px;
}
.innovative-icons-container .row:last-of-type {
  width: 80%;
  margin-bottom: 0;
}
.innovative-icons-container .row p {
  margin: 0 0 10px 0;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
}
.innovative-icons-container .row p.small {
  font-size: 16px;
  margin-bottom: 0;
  line-height: 20px;
}

.innovative-icon {
  max-width: 130px;
}
.innovative-icon h3.charcoal {
  margin: 0 0 10px 0;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
}

.icon-holder {
  width: 20px;
  height: 20px;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 15px;
}
.icon-holder img {
  width: 60px !important;
  position: absolute;
}

.dark-orange {
  background-color: #ff6b00;
}
.sky-blue {
  background-color: #6dcff6;
}
.pink {
  background-color: #f49ac1;
}
.peach {
  background-color: #fcb562;
}
.purp {
  background-color: #8a51b9;
}
.pea-green {
  background-color: #7cc576;
}
.hot-pink {
  background-color: #ec008c;
}
.lilac {
  background-color: #c19ade;
}
.true-blue {
  background-color: #00b1ff;
}

/*WE CARE ABOUT THE WORLD*/
.we-care {
  padding: 60px 0;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}
.we-care h2 {
  margin-bottom: 50px;
}

.care-two-col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.care-col {
  width: 44%;
  display: flex;
  flex-direction: row;
  padding: 26px 20px;
}
.care-col h2 {
  color: #fff;
  font-size: 34px;
  margin-bottom: 0;
  font-weight: 200;
}
.care-col p {
  font-weight: 200;
  line-height: 18px;
  font-size: 18px;
}

.care-col.one {
  background-color: #1cbbb4;
  border-bottom: 10px solid #8dddd9;
}
.care-col.two {
  background-color: #b17dd5;
  border-bottom: 10px solid #d8beea;
}
.care-col .care-copy {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 60%;
  padding: 0 40px;
}

@-moz-document url-prefix() {
  .why-grs-hero p.top {
    font-weight: 300 !important;
  }
}

@media (max-width: 800px) {
  .why-grs-hero {
    background-position: 62% center;
    padding: 100px 20px;
  }

  .why-body {
    padding-bottom: 0;
  }

  .why-grs-hero p.top {
    margin-bottom: 30px;
  }

  .why-section {
    flex-direction: column;
    margin-bottom: 60px;
  }
  .why-section .icon-container img {
    width: 100px;
    margin-bottom: 20px;
  }

  .innovative-body {
    padding-bottom: 0;
  }

  .innovative-icons-container .row,
  .innovative-icons-container .row:last-of-type {
    width: 100%;
    flex-wrap: wrap;
  }
  .innovative-icon {
    margin-bottom: 40px;
    max-width: 150px;
  }

  .care-two-col {
    flex-direction: column;
  }

  .care-col {
    width: 100%;
    flex-direction: column;
  }
  .care-col.one {
    margin-bottom: 50px;
  }
  .care-col .care-copy {
    width: 100%;
    padding: 0;
  }

  .care-icon {
    margin-bottom: 20px;
  }

  /*NEW STUFF MAR 2020*/
  .why-section.personalized-section .ai-row .container { flex-direction: column; }
  .why-section.personalized-section .ai-row .container img { width: 95%; }
  .why-section.personalized-section .ai-row .container .copy { width: 100%; }

  .personalized-video { max-height: 300px; }
  .personalized-video .img-transition {
    height: unset;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 1420px;
    height: 100%;
  }

  .why-section.personalized-section .ai-row .container img.cards {
    width: 88%;
    margin-bottom: 40px;
  }
}
</style>
