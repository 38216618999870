import { render, staticRenderFns } from "./HomepageIcons.vue?vue&type=template&id=7722ab62&"
var script = {}
import style0 from "./HomepageIcons.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports