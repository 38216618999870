<template>
  <transition name="fade" mode="out-in">
    <div class="gift-page-container">
      <nav-component></nav-component>
      <div class="gift-codes-header">
        <div class="centering">
          <div class="header-copy">
            <img src="@/assets/img/gift-codes.png" alt />
            <h1>The Virtual Gift Code that Delivers Instant Rewards</h1>
            <p>Redeemable in 85+ countries for merchandise, virtual rewards, retail gift cards, hotels, travel, experiences, event tickets, and mobile top-up! Purchase Gift Codes in the currency of your choice through our built-in converter and send them around the world with secure, encrypted code delivery.</p>
          </div>
          <img class="header-img" src="@/assets/img/gift-codes-header.png" alt />
        </div>
      </div>
      <colours-bar></colours-bar>
      <div class="solution-intro">
        <p
          class="charcoal"
        >Say farewell to the complexity of reward management with the speed and simplicity of Gift Codes. The best reward to boost loyalty, recognize performance, and engage employees.</p>
      </div>
      <div class="innovative-body">
        <h2>How You Can Use Gift Codes</h2>
        <div class="innovative-icons-container">
          <div class="row">
            <div class="innovative-icon with-transition-delay simple-fade">
              <div class="icon-holder true-blue">
                <img src="@/assets/img/gift1.png" alt />
              </div>
              <p class="charcoal">Global&nbsp;Rewards</p>
              <p
                class="charcoal small"
              >Customize and build your perfect rewards solution with variable denominations, from 10 cents, all the way to 2500 dollars.</p>
            </div>
            <div class="innovative-icon simple-fade">
              <div class="icon-holder hot-pink">
                <img src="@/assets/img/gift2.png" alt />
              </div>
              <p class="charcoal">Badges</p>
              <p
                class="charcoal small"
              >Power up by linking your Badges, social media, and performance metrics to specific Gift Codes values.</p>
            </div>
            <div class="innovative-icon simple-fade">
              <div class="icon-holder dark-green">
                <img src="@/assets/img/gift3.png" alt />
              </div>
              <p class="charcoal">Monetize Apps</p>
              <p
                class="charcoal small"
              >Solution providers can automatically monetize your apps using our quick connect API’s.</p>
            </div>
          </div>
          <div class="row">
            <div class="innovative-icon with-transition-delay simple-fade">
              <div class="icon-holder dark-orange">
                <img src="@/assets/img/innovation8.png" alt />
              </div>
              <p class="charcoal">Instant Gifting</p>
              <p
                class="charcoal small"
              >Say thank you with Gift Codes and instantly connect your recipients to millions of reward choices.</p>
            </div>
            <div class="innovative-icon simple-fade">
              <div class="icon-holder purp">
                <img src="@/assets/img/innovation6.png" alt />
              </div>
              <p class="charcoal">Research</p>
              <p
                class="charcoal small"
              >Increase panellist participation by offering instant recognition and greater reward choice.</p>
            </div>
            <div class="innovative-icon simple-fade">
              <div class="icon-holder rose">
                <img src="@/assets/img/gift6.png" alt />
              </div>
              <p class="charcoal">Instant Loyalty</p>
              <p
                class="charcoal small"
              >Instantly reward your members by connecting them to the world’s largest reward gallery.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="email-to-shop">
        <h2>Go from email to shopping in seconds!</h2>
        <p class="charcoal push-down-lil">Simple 2-step Redemption Process</p>
        <div class="full-width-img-top">
          <div class="inner">
            <div class="left copy">
              <h3>
                <span class="blue-dot">1</span> Activate
              </h3>
              <p class="charcoal">Redeem your Gift Codes.</p>
            </div>
            <div class="right simple-fade">
              <img src="@/assets/img/giftcodes-screens-1-new.png" alt />
            </div>
          </div>
        </div>
        <div class="full-width-img-bottom">
          <div class="inner">
            <div class="right simple-fade">
              <img src="@/assets/img/giftcodes-screens-2-new.png" alt />
            </div>
            <div class="left copy">
              <h3>
                <span class="blue-dot">2</span> Shop
              </h3>
              <p class="charcoal">Shop Instantly.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="salesforce-intg">
        <div class="container">
          <div class="copy">
            <h2>Salesforce Integration</h2>
            <p
              class="charcoal"
            >Download the GRS APP within Salesforce to instantly reward your sales or channel partners for their success. Gift Codes is a native Salesforce APP, so now you can buy, reward and shop with your Gift Codes all within Salesforce.</p>
            <div class="learn-more">
              <a
                href="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N30000000qD9wEAE"
                target="_blank"
              >
                <button class="learn-more-btn charcoal-btn">GET IT NOW ON APPEXCHANGE</button>
              </a>
            </div>
          </div>
          <div class="salesforce-logo-container simple-fade">
            <img src="@/assets/img/salesforce-blue.png" alt />
          </div>
        </div>
      </div>
      <div class="global-gift-codes">
        <h3 class="charcoal">Purchase Global Gift Codes</h3>
        <p
          class="charcoal"
        >Gift Codes are redeemable in 85 countries, simply pick the Gift Code that matches your recipients interests</p>
        <div class="global-cards-container">
          <div class="row">
            <div class="card">
              <img src="@/assets/img/g-everything.jpg" class="card-img" alt />
              <h3>The Everything Code</h3>
              <p class="charcoal">Redeemable towards every reward type.</p>
            </div>
            <div class="card">
              <img src="@/assets/img/g-digital.jpg" class="card-img" alt />
              <h3>Digital Gift Codes</h3>
              <p class="charcoal">Includes mobile top-up, gift cards and travel.</p>
            </div>
            <div class="card">
              <img src="@/assets/img/g-mobile.jpg" class="card-img" alt />
              <h3>Gift Codes for Mobile</h3>
              <p
                class="charcoal"
              >Choose your carrier and receive an instant credit with just one click.</p>
            </div>
          </div>
          <div class="row">
            <div class="card">
              <img src="@/assets/img/g-gift.jpg" class="card-img" alt />
              <h3>Global Retail Gift Cards</h3>
              <p class="charcoal">Thousands of the world’s best retail gift cards to choose from.</p>
            </div>
            <div class="card">
              <img src="@/assets/img/g-travel.jpg" class="card-img" alt />
              <h3>Gift Codes Travel</h3>
              <p class="charcoal">Over 400,000 hotels plus cars, cruises, airlines and more...</p>
            </div>
            <div class="card">
              <img src="@/assets/img/g-savings.jpg" class="card-img" alt />
              <h3>Gift Codes Savings</h3>
              <p
                class="charcoal"
              >Save at hundreds of thousands of retail stores throughout North America.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="features-benefits">
        <h2>Features and Benefits</h2>
        <div class="features-list">
          <div class="left">
            <p class="checkmark">The world’s most comprehensive global shopping mall.</p>
            <p class="checkmark">Locally sourced and delivered rewards.</p>
            <p class="checkmark">Secure, encrypted API code delivery system.</p>
            <p class="checkmark">Connect directly to our API to order Gift Codes in real time.</p>
            <p class="checkmark">Secure mobile wallet with credit card top-up.</p>
          </div>
          <div class="right">
            <p class="checkmark">Buy single or bulk Gift Codes in any currency.</p>
            <p
              class="checkmark"
            >Users can bank points or instantly redeem by topping-up with Paypal.</p>
            <p class="checkmark">Fast and easy reward redemption for your members.</p>
            <p class="checkmark">Instantly shop in 85 countries.</p>
          </div>
        </div>
      </div>
      <footer-component></footer-component>
    </div>
  </transition>
</template>

<script>
export default {
  data: function () {
    return {
      name: 'gift-codes'
    }
  }
}
</script>

<style>
/*HEADER/HERO*/
.gift-codes-header {
  background-color: #006eff;
  width: 100%;
  padding: 100px 0 30px;
}

.gift-codes-header .centering {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}
.gift-codes-header .header-copy {
  padding-top: 40px;
}
.header-copy h1 {
  font-size: 60px;
  font-weight: 400;
  margin: 10px 0;
}
.header-copy p {
  font-weight: 200;
  line-height: 22px;
  font-size: 18px;
  width: 90%;
}
.gift-codes-header .header-img {
  width: 40%;
  height: auto;
}

@-moz-document url-prefix() {
  .gift-page-container .gift-codes-header .header-img {
    width: 108%;
  }
}

/*MAIN PAGE BODY SECTION*/
.solution-intro {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  padding: 40px 0;
}

.innovative-body {
  background-color: #eeeeee;
  padding: 60px 0 10px;
  text-align: center;
}

.innovative-icons-container {
  margin: 0 auto 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}
.innovative-icons-container .row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 70%;
  margin-bottom: 30px;
}
.innovative-icons-container .row p {
  margin: 0 0 10px 0;
  text-align: center;
}
.innovative-icons-container .row p.small {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 200;
}

.innovative-icon {
  max-width: 150px;
}

.icon-holder {
  width: 20px;
  height: 20px;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto 15px;
}
.icon-holder img {
  width: 60px !important;
  position: absolute;
}

.dark-orange {
  background-color: #ff6b00;
}
.dark-green {
  background-color: #52b100;
}
.purp {
  background-color: #8a51b9;
}
.hot-pink {
  background-color: #ec008c;
}
.true-blue {
  background-color: #00b1ff;
}

.container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.container h2 {
  margin-bottom: 20px;
}

.container p {
  width: 70%;
  margin-bottom: 40px;
}

.salesforce-intg {
  background-color: #edf5ff;
}

.salesforce-intg .container {
  width: 80%;
  max-width: 1200px;
  padding: 80px 0;
}

.salesforce-logo-container img {
  width: 275px;
}

.email-to-shop {
  text-align: center;
  padding: 60px 0;
}
.email-to-shop h2 {
  margin-bottom: 30px;
}
.email-to-shop p.push-down-lil {
  margin-bottom: 50px;
}

.full-width-img-top {
  background-image: url(../assets/img/blue-stripe-top.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-bottom: 60px;
}

.full-width-img-top h3,
.full-width-img-bottom h3 {
  margin: 0;
  font-weight: 300;
  color: #636466;
  font-size: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.full-width-img-top .inner,
.full-width-img-bottom .inner {
  position: relative;
  top: -15px;
}

.full-width-img-bottom {
  background-image: url(../assets/img/blue-stripe-bottom.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.full-width-img-top .inner,
.full-width-img-bottom .inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.full-width-img-top .left,
.full-width-img-top .right,
.full-width-img-bottom .left,
.full-width-img-bottom .right {
  width: 50%;
}

.full-width-img-top .left,
.full-width-img-bottom .right {
  position: relative;
  top: -5px;
}

.full-width-img-top .right img,
.full-width-img-bottom .right img {
  width: 90%;
}

span.blue-dot {
  background-color: #006eff;
  border-radius: 50%;
  padding: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*margin-bottom: 10px;*/
}

.global-gift-codes {
  text-align: center;
  padding: 60px 0;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}
.global-gift-codes h3 {
  margin-bottom: 0;
  font-weight: 300;
  color: #636466;
  font-size: 46px;
}

.global-cards-container .row {
  justify-content: space-between;
  margin-top: 70px;
  align-items: flex-start;
}

.card {
  width: 30%;
}
.card h3 {
  font-size: 30px;
  font-weight: 300;
  color: #636466;
  margin: 0;
}

.card-img {
  width: 100%;
  margin-bottom: 20px;
  /*box-shadow: -7px -4px 39px -5px rgba(0,0,0,0.39);*/
}

.features-benefits {
  background-color: #006eff;
  padding: 60px 0;
  text-align: center;
}
.features-benefits h2 {
  color: #fff;
  margin-bottom: 10px;
}
.features-list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: left;
  width: 80%;
  margin: 0 auto;
}

p.checkmark {
  background: transparent url(../assets/img/checkmark.png) no-repeat;
  background-size: 25px;
  background-position: left center;
  line-height: 25px;
  padding-left: 40px;
  font-weight: 300;
}

@media (max-width: 800px) {
  .gift-codes-header img.header-img {
    display: none;
  }
  .gift-codes-header .header-copy {
    padding-top: 0;
  }
  .gift-codes-header .header-copy h1 {
    font-size: 42px;
  }

  .solution-intro {
    width: 90% !important;
  }

  .gift-page-container .innovative-icons-container .row {
    width: 100%;
  }
  .gift-page-container .innovative-icon .icon-holder {
    width: 0px;
    height: 0px;
  }

  .gift-page-container .full-width-img-top .copy h2 {
    font-size: 32px;
  }

  .gift-page-container .full-width-img-top .inner .left.copy,
  .gift-page-container .full-width-img-bottom .inner .left.copy {
    position: relative;
    top: 23px;
  }

  .gift-page-container .salesforce-intg .container {
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
  }
  .gift-page-container .salesforce-intg .container .copy {
    text-align: center;
  }
  .gift-page-container .salesforce-intg .container .copy p {
    width: 90%;
    margin: 0 auto 40px;
  }

  .gift-page-container .salesforce-logo-container {
    margin: 40px 0 0;
  }

  .global-cards-container .row {
    flex-direction: column;
  }
  .global-cards-container .row:last-of-type {
    margin-top: 0;
  }
  .global-cards-container .row .card {
    width: 100%;
    margin-bottom: 40px;
  }

  .features-list {
    flex-direction: column;
  }

  span.blue-dot {
    padding: 10px;
    width: 30px;
    height: 30px;
  }

  .full-width-img-top h3,
  .full-width-img-bottom h3 {
    font-size: 30px;
  }

  .full-width-img-top .left.copy p,
  .full-width-img-bottom .left.copy p {
    margin: 0;
  }
}
</style>
