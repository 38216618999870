<template>
  <transition name="fade" mode="out-in">
    <div class="global-page-container">
      <nav-component></nav-component>
      <div class="global-savings-header">
        <div class="centering">
          <div class="header-copy">
            <img src="@/assets/img/global-savings.png" alt="">
            <h1>Exclusive Savings on the World’s Top&nbsp;Brands</h1>
            <p>Imagine being able to save hundreds of dollars booking a stay at your favourite destination on a laptop that you purchased for 20% off, and then having access to local deals on dining and things to do when you get there. This is the power of Global Savings.</p>
          </div>
          <img class="header-img" src="@/assets/img/global-savings-header.png" alt="">
        </div>
      </div>
      <colours-bar></colours-bar>
      <div class="solution-intro">
        <p class="charcoal">Choose from hundreds of thousands of specially priced offers with exclusive savings off the top brand name 
        merchandise, dining, travel, mobile top-up, gift cards, entertainment, and much more!</p>
      </div>
      <tabs :options="{ useUrlFragment: false, defaultTabHash: 'unitedStates' }">
        <tab id="unitedStates" name="United States">
          <div class="savings-container">
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/movie-tickets.png" alt="" class="savings-icon">
                <h2 class="savings-title">Movie Tickets</h2>
                <p class="charcoal savings-description">Enjoy exclusive discounts on the latest blockbusters at theatres across the country.</p>
              </div>
              <div class="brands">
                <img src="@/assets/img/amc.png" alt="">
                <img src="@/assets/img/cinemark.png" alt="">
                <img src="@/assets/img/regal.png" alt="">
                <img src="@/assets/img/showcase.png" alt="">
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/gift-cards.png" alt="" class="savings-icon">
                <h2 class="savings-title">Gift Cards</h2>
                <p class="charcoal savings-description">Exclusive savings on over 120 gift card brands from around the globe.</p>
              </div>
              <div class="brands">
                <img src="@/assets/img/gift-cards-1.png" alt="">
                <img src="@/assets/img/gift-cards-2.png" alt="">
                <img src="@/assets/img/gift-cards-3.png" alt="">
                <img src="@/assets/img/gift-cards-4.png" alt="">
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/dining.png" alt="" class="savings-icon">
                <h2 class="savings-title">Dining & Attractions</h2>
                <p class="charcoal savings-description">Exclusive local discounts on your favorite dining and attractions.</p>
              </div>
              <div class="brands">
                <img src="@/assets/img/dining-1.png" alt="">
                <img src="@/assets/img/dining-2.png" alt="">
                <img src="@/assets/img/dining-3.png" alt="">
                <img src="@/assets/img/dining-4.png" alt="">
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/hotels.png" alt="" class="savings-icon">
                <h2 class="savings-title">Hotels</h2>
                <p class="charcoal savings-description">Exclusive savings on 400,000+ hotel stays through our private portal. Huge discounts compared with major online travel sites.</p>
              </div>
              <div class="brands with-copy">
                <div class="hotel">
                  <img src="@/assets/img/hotels-1.png" alt="">
                  <p><strong>New York</strong></p>
                  <p>Cassa Hotel NY</p>
                  <p>45th Street<br></p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/hotels-2.png" alt="">
                  <p><strong>Chicago</strong></p>
                  <p>Freehand</p>
                  <p>Chicago&nbsp;Hotel<br></p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/hotels-3.png" alt="">
                  <p><strong>Orlando</strong></p>
                  <p>Days Hotel</p>
                  <p>Disney&nbsp;Maingate</p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/hotels-4.png" alt="">
                  <p><strong>San Francisco</strong></p>
                  <p>Kimpton Sr</p>
                  <p>Francis&nbsp;Drake&nbsp;Hotel</p>
                </div>
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/cruises.png" alt="" class="savings-icon">
                <h2 class="savings-title">Cruises & Car Rentals</h2>
                <p class="charcoal savings-description">Save on the best cruises and car rentals around the globe. Exclusive membership savings over and above major online travel sites.</p>
              </div>
              <div class="brands">
                <img src="@/assets/img/cruises-1.png" class="wider" alt="">
                <img src="@/assets/img/cruises-2.png" alt="">
                <img src="@/assets/img/cruises-3.png" alt="">
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/luxury-homes.png" alt="" class="savings-icon">
                <h2 class="savings-title">Luxury Homes</h2>
                <p class="charcoal savings-description">1000’s of private luxury vacation home experiences available around the world at exclusive discounted rates.</p>
              </div>
              <div class="brands with-copy">
                <div class="hotel">
                  <img src="@/assets/img/luxury-1.png" alt="">
                  <p><strong>Los Cabos, Mexico</strong></p>
                  <p>Cielos 85</p>
                  <p>4 Bedrooms<br></p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/luxury-2.png" alt="">
                  <p><strong>Jackson Hole, Wyoming</strong></p>
                  <p>Aman Springs</p>
                  <p>4 Bedrooms<br></p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/luxury-3.png" alt="">
                  <p><strong>Playa Flamingo, Costa Rica</strong></p>
                  <p>Casa Playa Bonita</p>
                  <p>5 Bedrooms</p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/luxury-4.png" alt="">
                  <p><strong>Lake Tahoe, California</strong></p>
                  <p>Penthouse</p>
                  <p>3 Bedrooms</p>
                </div>
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/weekly-stays.png" alt="" class="savings-icon">
                <h2 class="savings-title">Weekly Stays</h2>
                <p class="charcoal savings-description">Exclusive members only savings on 140,000+ weekly stays at resorts around the world.</p>
              </div>
              <div class="brands with-copy">
                <div class="hotel">
                  <img src="@/assets/img/weekly-1.png" alt="">
                  <p><strong>Freeport,Bahamas</strong></p>
                  <p>The Marlin at Taino Beach</p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/weekly-2.png" alt="">
                  <p><strong>Playa del Carmen, Mexico</strong></p>
                  <p>Mayan Palace at Vidanta</p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/weekly-3.png" alt="">
                  <p><strong>Higuey, Dominican Rep</strong></p>
                  <p>Catalonia Bavaro Beach Resort</p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/weekly-4.png" alt="">
                  <p><strong>Lahaina, Hawaii</strong></p>
                  <p>Kahana Falls</p>
                </div>
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/events.png" alt="" class="savings-icon">
                <h2 class="savings-title">Events</h2>
                <p class="charcoal savings-description">Private access to sold out tickets for shows, concerts, sports and other exciting events.</p>
              </div>
              <div class="brands events">
                <img src="@/assets/img/events-1.png" alt="">
                <img src="@/assets/img/events-2.png" alt="">
                <img src="@/assets/img/events-3.png" alt="">
                <img src="@/assets/img/events-4.png" alt="">
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/mobile-top-up.png" alt="" class="savings-icon">
                <h2 class="savings-title">Mobile Top Up</h2>
                <p class="charcoal savings-description">Save on mobile top-up with exclusive discounts on the biggest carriers in the country.</p>
              </div>
              <div class="brands events">
                <img src="@/assets/img/att.png" alt="">
                <img src="@/assets/img/verizon.png" alt="">
                <img src="@/assets/img/t-mobile.png" alt="">
                <img src="@/assets/img/southern.png" alt="">
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/merchandise.png" alt="" class="savings-icon">
                <h2 class="savings-title">Merchandise</h2>
                <p class="charcoal savings-description">Exclusive savings on 100,000+ products from the world’s most popular brands.</p>
              </div>
              <div class="brands">
                <img src="@/assets/img/merch-1.png" alt="">
                <img src="@/assets/img/merch-2.png" alt="">
                <img src="@/assets/img/merch-3.png" alt="">
                <img src="@/assets/img/merch-4.png" alt="">
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/retail-savings.png" alt="" class="savings-icon">
                <h2 class="savings-title">Retail Savings</h2>
                <p class="charcoal savings-description">Receive exclusive major discounts from top online retailers.</p>
              </div>
              <div class="brands">
                <img src="@/assets/img/retail-1.png" alt="">
                <img src="@/assets/img/retail-2.png" alt="">
                <img src="@/assets/img/retail-3.png" alt="">
                <img src="@/assets/img/retail-4.png" alt="">
              </div>
            </div>
          </div>
        </tab>
        <tab id="canada" name="Canada">
          <div class="savings-container">
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/gift-cards.png" alt="" class="savings-icon">
                <h2 class="savings-title">Gift Cards</h2>
                <p class="charcoal savings-description">Exclusive savings on over 120 gift card brands from around the globe.</p>
              </div>
              <div class="brands">
                <img src="@/assets/img/gift-cards-1.png" alt="">
                <img src="@/assets/img/gift-cards-2.png" alt="">
                <img src="@/assets/img/canada-gc-3.png" alt="">
                <img src="@/assets/img/canada-gc-4.png" alt="">
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/dining.png" alt="" class="savings-icon">
                <h2 class="savings-title">Dining & Attractions</h2>
                <p class="charcoal savings-description">Exclusive local discounts on your favorite dining and attractions.</p>
              </div>
              <div class="brands">
                <img src="@/assets/img/dining-1.png" alt="">
                <img src="@/assets/img/dining-2.png" alt="">
                <img src="@/assets/img/dining-3.png" alt="">
                <img src="@/assets/img/dining-4.png" alt="">
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/hotels.png" alt="" class="savings-icon">
                <h2 class="savings-title">Hotels</h2>
                <p class="charcoal savings-description">Exclusive savings on 400,000+ hotel stays through our private portal. Huge discounts compared with major online travel sites.</p>
              </div>
              <div class="brands with-copy">
                <div class="hotel">
                  <img src="@/assets/img/hotels-1.png" alt="">
                  <p><strong>New York</strong></p>
                  <p>Cassa Hotel NY</p>
                  <p>45th Street<br></p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/hotels-canada-2.png" alt="">
                  <p><strong>Toronto</strong></p>
                  <p>Holiday Inn</p>
                  <p>Toronto<br></p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/hotels-3.png" alt="">
                  <p><strong>Orlando</strong></p>
                  <p>Days Hotel</p>
                  <p>Disney&nbsp;Maingate</p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/hotels-canada-4.png" alt="">
                  <p><strong>Vancouver</strong></p>
                  <p>Holiday Inn</p>
                  <p>Vancouver&nbsp;Airport</p>
                </div>
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/cruises.png" alt="" class="savings-icon">
                <h2 class="savings-title">Cruises & Car Rentals</h2>
                <p class="charcoal savings-description">Save on the best cruises and car rentals around the globe. Exclusive membership savings over and above major online travel sites.</p>
              </div>
              <div class="brands">
                <img src="@/assets/img/cruises-1.png" class="wider" alt="">
                <img src="@/assets/img/cruises-2.png" alt="">
                <img src="@/assets/img/cruises-3.png" alt="">
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/luxury-homes.png" alt="" class="savings-icon">
                <h2 class="savings-title">Luxury Homes</h2>
                <p class="charcoal savings-description">1000’s of private luxury vacation home experiences available around the world at exclusive discounted rates.</p>
              </div>
              <div class="brands with-copy">
                <div class="hotel">
                  <img src="@/assets/img/luxury-1.png" alt="">
                  <p><strong>Los Cabos, Mexico</strong></p>
                  <p>Cielos 85</p>
                  <p>4 Bedrooms<br></p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/luxury-2.png" alt="">
                  <p><strong>Jackson Hole, Wyoming</strong></p>
                  <p>Aman Springs</p>
                  <p>4 Bedrooms<br></p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/luxury-3.png" alt="">
                  <p><strong>Playa Flamingo, Costa Rica</strong></p>
                  <p>Casa Playa Bonita</p>
                  <p>5 Bedrooms</p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/luxury-4.png" alt="">
                  <p><strong>Lake Tahoe, California</strong></p>
                  <p>Penthouse</p>
                  <p>3 Bedrooms</p>
                </div>
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/weekly-stays.png" alt="" class="savings-icon">
                <h2 class="savings-title">Weekly Stays</h2>
                <p class="charcoal savings-description">Exclusive members only savings on 140,000+ weekly stays at resorts around the world.</p>
              </div>
              <div class="brands with-copy">
                <div class="hotel">
                  <img src="@/assets/img/weekly-1.png" alt="">
                  <p><strong>Freeport,Bahamas</strong></p>
                  <p>The Marlin at Taino Beach</p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/weekly-2.png" alt="">
                  <p><strong>Playa del Carmen, Mexico</strong></p>
                  <p>Mayan Palace at Vidanta</p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/weekly-3.png" alt="">
                  <p><strong>Higuey, Dominican Rep</strong></p>
                  <p>Catalonia Bavaro Beach Resort</p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/weekly-4.png" alt="">
                  <p><strong>Lahaina, Hawaii</strong></p>
                  <p>Kahana Falls</p>
                </div>
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/events.png" alt="" class="savings-icon">
                <h2 class="savings-title">Events</h2>
                <p class="charcoal savings-description">Private access to sold out tickets for shows, concerts, sports and other exciting events.</p>
              </div>
              <div class="brands events">
                <img src="@/assets/img/events-1.png" alt="">
                <img src="@/assets/img/events-2.png" alt="">
                <img src="@/assets/img/events-3.png" alt="">
                <img src="@/assets/img/events-4.png" alt="">
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/merchandise.png" alt="" class="savings-icon">
                <h2 class="savings-title">Merchandise</h2>
                <p class="charcoal savings-description">Exclusive savings on 100,000+ products from the world’s most popular brands.</p>
              </div>
              <div class="brands">
                <img src="@/assets/img/merch-1.png" alt="">
                <img src="@/assets/img/merch-2.png" alt="">
                <img src="@/assets/img/merch-3.png" alt="">
                <img src="@/assets/img/merch-4.png" alt="">
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/retail-savings.png" alt="" class="savings-icon">
                <h2 class="savings-title">Retail Savings</h2>
                <p class="charcoal savings-description">Receive exclusive major discounts from top online retailers.</p>
              </div>
              <div class="brands">
                <img src="@/assets/img/retail-1.png" alt="">
                <img src="@/assets/img/retail-2.png" alt="">
                <img src="@/assets/img/retail-3.png" alt="">
                <img src="@/assets/img/retail-4.png" alt="">
              </div>
            </div>
          </div>
        </tab>
        <tab id="unitedKingdom" name="United Kingdom">
          <div class="savings-container">
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/gift-cards.png" alt="" class="savings-icon">
                <h2 class="savings-title">Gift Cards</h2>
                <p class="charcoal savings-description">Exclusive savings on over 120 gift card brands from around the globe.</p>
              </div>
              <div class="brands">
                <img src="@/assets/img/uk-gc-1.png" alt="">
                <img src="@/assets/img/uk-gc-2.png" alt="">
                <img src="@/assets/img/uk-gc-3.png" alt="">
                <img src="@/assets/img/uk-gc-4.png" alt="">
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/dining.png" alt="" class="savings-icon">
                <h2 class="savings-title">Dining & Attractions</h2>
                <p class="charcoal savings-description">Exclusive local discounts on your favorite dining and attractions.</p>
              </div>
              <div class="brands">
                <img src="@/assets/img/uk-dining-1.png" alt="">
                <img src="@/assets/img/uk-dining-2.png" alt="">
                <img src="@/assets/img/uk-dining-3.png" alt="">
                <img src="@/assets/img/uk-dining-4.png" alt="">
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/hotels.png" alt="" class="savings-icon">
                <h2 class="savings-title">Hotels</h2>
                <p class="charcoal savings-description">Exclusive savings on 400,000+ hotel stays through our private portal. Huge discounts compared with major online travel sites.</p>
              </div>
              <div class="brands with-copy">
                <div class="hotel">
                  <img src="@/assets/img/uk-hotels-1.png" alt="">
                  <p><strong>London</strong></p>
                  <p>Hilton London</p>
                  <p>Metropole<br></p>
                </div>
                <div class="hotel">
                  <img src="@/assets/img/uk-hotels-2.png" alt="">
                  <p><strong>Paris</strong></p>
                  <p>Villa Allessandra</p>
                  <!-- <p>Chicago&nbsp;Hotel<br></p> -->
                </div>
                <div class="hotel">
                  <img src="@/assets/img/uk-hotels-3.png" alt="">
                  <p><strong>Rome</strong></p>
                  <p>Hotel Lord Byron</p>
                  <!-- <p>Disney&nbsp;Maingate</p> -->
                </div>
                <div class="hotel">
                  <img src="@/assets/img/uk-hotels-4.png" alt="">
                  <p><strong>Munich</strong></p>
                  <p>Das Seidl Hotel</p>
                  <p>&&nbsp;Tagung</p>
                </div>
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/cruises.png" alt="" class="savings-icon">
                <h2 class="savings-title">Cruises & Car Rentals</h2>
                <p class="charcoal savings-description">Save on the best cruises and car rentals around the globe. Exclusive membership savings over and above major online travel sites.</p>
              </div>
              <div class="brands">
                <img src="@/assets/img/cruises-1.png" class="wider" alt="">
                <img src="@/assets/img/cruises-2.png" alt="">
                <img src="@/assets/img/cruises-3.png" alt="">
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/events.png" alt="" class="savings-icon">
                <h2 class="savings-title">Events</h2>
                <p class="charcoal savings-description">Private access to sold out tickets for shows, concerts, sports and other exciting events.</p>
              </div>
              <div class="brands events">
                <img src="@/assets/img/uk-events-1.png" alt="">
                <img src="@/assets/img/uk-events-2.png" alt="">
                <img src="@/assets/img/uk-events-3.png" alt="">
                <img src="@/assets/img/uk-events-4.png" alt="">
              </div>
            </div>
            <div class="row savings-row">
              <div class="savings-info">
                <img src="@/assets/img/retail-savings.png" alt="" class="savings-icon">
                <h2 class="savings-title">Retail Savings</h2>
                <p class="charcoal savings-description">Receive exclusive major discounts from top online retailers.</p>
              </div>
              <div class="brands">
                <img src="@/assets/img/uk-retail-1.png" alt="">
                <img src="@/assets/img/uk-retail-2.png" alt="">
                <img src="@/assets/img/uk-retail-3.png" alt="">
                <img src="@/assets/img/uk-retail-4.png" alt="">
              </div>
            </div>
          </div>
        </tab>
      </tabs>
      <div class="jane">
        <div class="jane-header">
          <img src="@/assets/img/jane.png" alt="">
          <h3>Alicia saved $1,629 on a weekender to New York</h3>
        </div>
        <table class="janes-table">
          <tr>
            <th>Activity</th>
            <th>Vendor</th>
            <th>Regular Price</th>
            <th>Discounted Price</th>
            <th>Savings</th>
          </tr>
          <tr>
            <td>Used laptop to research trip</td>
            <td>Acer</td>
            <td>$1,221.26</td>
            <td>$694.70</td>
            <td>$526.56</td>
          </tr>
          <tr>
            <td>Booked hotel</td>
            <td>Residence Inn by Marriott </td>
            <td>$2,063.00</td>
            <td>$1,034.00</td>
            <td>$1,029.00 </td>
          </tr>
          <tr>
            <td>Dinner</td>
            <td>Applebee’s</td>
            <td>$30.00</td>
            <td>$27.00</td>
            <td>$3.00</td>
          </tr>
          <tr>
            <td>Breakfast</td>
            <td>IHOP</td>
            <td>$14.00</td>
            <td>$12.60</td>
            <td>$1.40</td>
          </tr>
          <tr>
            <td>Went on a Sporanos Tour</td>
            <td>On Location Tours</td>
            <td>$54.00</td>
            <td>$27.00</td>
            <td>$27.00</td>
          </tr>
          <tr>
            <td>Attended a show</td>
            <td>The Lion King</td>
            <td>$230.00</td>
            <td>$207.00</td>
            <td>$23.00</td>
          </tr>
          <tr>
            <td>Lunch</td>
            <td>Dante’s Gourmet Foods </td>
            <td>$20.00</td>
            <td>$15.00</td>
            <td>$5.00</td>
          </tr>
          <tr>
            <td>Sightseeing</td>
            <td>One World Observatory</td>
            <td>$37.02</td>
            <td>$29.00</td>
            <td>$8.02</td>
          </tr>
          <tr>
            <td>Dinner</td>
            <td>Bento Sushi</td>
            <td>$20.00</td>
            <td>$14.00</td>
            <td>$6.00</td>
          </tr>
          <tr>
            <td>Breakfast</td>
            <td>Subway</td>
            <td>$7.00</td>
            <td>$6.00</td>
            <td>$1.00</td>
          </tr>
        </table>
        <div class="total-savings-container">
          <h2 class="total-savings">TOTAL SAVINGS: $1,629.98</h2>
        </div>
      </div>
      <div class="smiths">
        <div class="smiths-header">
          <img src="@/assets/img/smiths.png" alt="">
          <h3>The Rahal’s saved $2,322.26 on their Orlando family vacation</h3>
        </div>
        <table class="smiths-table">
          <tr>
            <th>Activity</th>
            <th>Vendor</th>
            <th>Regular Price</th>
            <th>Discounted Price</th>
            <th>Savings</th>
          </tr>
          <tr>
            <td>Used iPad to research trip</td>
            <td>Apple</td>
            <td>$1,221.26</td>
            <td>$953.64</td>
            <td>$215.36</td>
          </tr>
          <tr>
            <td>Booked hotel</td>
            <td>Wyndham Grand Orlando</td>
            <td>$3,340.00</td>
            <td>$2,136.00</td>
            <td>$1,204.00</td>
          </tr>
          <tr>
            <td>Car Rental</td>
            <td>AVIS</td>
            <td>$369.00</td>
            <td>$222.00</td>
            <td>$147.00</td>
          </tr>
          <tr>
            <td>Theme park tickets for 4</td>
            <td>4-day pass to Disney World</td>
            <td>$1,951.33</td>
            <td>$1,757.27</td>
            <td>$194.06</td>
          </tr>
          <tr>
            <td>Theme park tickets for 4</td>
            <td>2-day pass to Universal Studios</td>
            <td>$1,439.86</td>
            <td>$1,134.00</td>
            <td>$305.86</td>
          </tr>
          <tr>
            <td>Theme park tickets for 4</td>
            <td>SeaWorld Orlando</td>
            <td>$425.96</td>
            <td>$187.98</td>
            <td>$237.98</td>
          </tr>
          <tr>
            <td>Dining</td>
            <td>Rainforest Café</td>
            <td>$80.00</td>
            <td>$75.00</td>
            <td>$5.00</td>
          </tr>
          <tr>
            <td>Dining</td>
            <td>Taco Bell</td>
            <td>$40.00</td>
            <td>$37.00</td>
            <td>$3.00</td>
          </tr>
          <tr>
            <td>Dining</td>
            <td>Giovanni’s Italian Restaurant</td>
            <td>$80.00</td>
            <td>$75.00</td>
            <td>$5.00</td>
          </tr>
          <tr>
            <td>Dessert</td>
            <td>Marble Slab Creamery</td>
            <td>$25.00</td>
            <td>$20.00</td>
            <td>$5.00</td>
          </tr>
        </table>
        <div class="total-savings-container">
          <h2 class="total-savings">TOTAL SAVINGS: $2,322.26</h2>
        </div>
      </div>
      <div class="additional">
        <div class="additional-container">
          <div class="icon">
            <img src="@/assets/img/additional-icon.png" alt="">
          </div>
          <div class="additional-copy">
            <h2>Additional International Savings</h2>
            <p>Enjoy additional exclusive savings on the most recognizable retail brands, travel, and attractions from around the globe.</p>
            <div class="bottom-lists">
              <div class="left">
                <p><strong>North America</strong>
                <br>Canada
                <br>United States</p>
                <p><strong>Asia</strong>
                <br>Australia
                <br>New Zealand</p>
              </div>
              <div class="right-side">
                <div class="right">
                  <p><strong>Europe</strong>
                  <br>Austria
                  <br>Balearic Islands
                  <br>Belgium
                  <br>Canary Islands
                  <br>Croatia
                  <br>Czech Republic
                  <br>Denmark</p>
                </div>
                <div class="right">
                  <p>Egypt
                  <br>Estonia
                  <br>Finland
                  <br>France
                  <br>Germany
                  <br>Greece
                  <br>Hungary</p>
                </div>
                <div class="right">
                  <p>Iceland
                  <br>Ireland
                  <br>Italy
                  <br>Spain
                  <br>Tunisia
                  <br>Turkey
                  <br>United Kingdom</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="member-benefits">
        <h2>Global Savings member benefits:</h2>
        <div class="features-list">
          <div class="left">
            <p class="checkmark">Unlimited access to new and existing global, regional, and local<br>rewards and discounts online and in-store</p>
            <p class="checkmark">Global “Members Only” travel portal</p>
            <p class="checkmark">Low prices far below market value</p>
            <p class="checkmark">Increased choice of relevant and appealing benefits to suit<br>individuals’ lifestyle and travel</p>
            <p class="checkmark">Discounts that are not time limited</p>
            <p class="checkmark">Additional discounts each week on 35,000+ items</p>
          </div>
          <div class="right">
            <p class="checkmark">Reward data refreshes daily including special timesensitive discounts<br>
            across all categories</p>
            <p class="checkmark">Access to sold out concerts</p>
            <p class="checkmark">Wider selection, plus highly discounted same-day seats to sports<br>
            and attractions</p>
            <p class="checkmark">Seamless and easy activation process to access online and<br>
            in store benefits</p>
            <p class="checkmark">Everyday added value and more ways to save</p>
          </div>
        </div>
      </div>
      <footer-component></footer-component>
    </div>
  </transition>
</template>

<script>
  export default {
    data: function() {
      return {
        name: 'global-savings'
      }
    }
  }
</script>

<style>
  /*HEADER/HERO*/
  .global-savings-header {
    background-color: #52b100;
    width: 100%;
    padding: 100px 0 30px;
  }

  .global-savings-header .centering {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .global-savings-header .header-copy { padding-top: 40px; }
  .header-copy h1 {
    font-size: 60px;
    font-weight: 400;
    margin: 10px 0; 
  }
  .header-copy p { width: 90%; }
  .global-savings-header .header-img {
    width: 40%;
    height: auto;
  }

  @-moz-document url-prefix() { 
    .global-page-container .global-savings-header .header-img { width: 90%; }
  }

  /*MAIN PAGE BODY CONTENT*/
  .solution-intro {
    width: 60% !important;
    margin: 0 auto;
    text-align: center;
    padding: 40px 0;
  }

  .savings-row {
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    margin: 0 auto;
    border-bottom: 1px solid #acacac;
    padding: 40px 0;
  }
  .savings-row:last-of-type {
    border-bottom: none;
    margin-bottom: 40px;
  }
  .savings-row .savings-info { width: 40%; }
  .savings-row img.savings-icon {
    width: 50px;
    margin: 20px 0;
  }
  .savings-row h2 {
    margin-bottom: 0;
    font-size: 34px;
  }
  .savings-row p { width: 70%; }
  .savings-row .brands {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 60%;
  }
  .savings-row .brands.events { align-items: center; }
  .savings-row .brands.with-copy {
    /*justify-content: flex-start;*/
    align-items: flex-start;
  }
  .savings-row .brands img { width: 90px; }
  .savings-row .brands img.wider { width: 210px; }
  .savings-row .brands.with-copy img {
    width: 110px; 
    margin-bottom: 10px;
  }

  .hotel {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .hotel strong, 
  .hotel p {
    color: #636466 !important;
    font-size: 12px;
    margin: 5px 0;
  }

  .jane {
    background-image: url('../assets/img/jane-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 60px 0;
  }
  .jane-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .jane-header h3 {
    color: #fff;
    margin: 0 40px;
    font-size: 34px;
    font-weight: 300;
  }
  .jane-header img { width: 120px; }
  .janes-table {
    width: 80%;
    max-width: 1200px;
    margin: 40px auto 0;
    border-collapse: collapse;
    font-size: 120%;
  }
  .janes-table tr {
    text-align: left;
    border-bottom: 1px solid #fff;
  }
  .janes-table tr th {
    font-weight: 600;
    border-bottom: 1px solid #fff;
    padding-bottom: 8px;
  }
  .janes-table tr td {
    font-weight: 200;
    padding: 8px 0;
    border-bottom: 1px solid #fff;
  }

  .smiths {
    background-image: url('../assets/img/smiths-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 60px 0;
  }
  .smiths-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .smiths-header h3 {
    color: #fff;
    margin: 0 40px;
    font-size: 34px;
    font-weight: 300;
  }
  .smiths-header img { width: 120px; }
  .smiths-table {
    width: 80%;
    max-width: 1200px;
    margin: 40px auto 0;
    border-collapse: collapse;
    font-size: 120%;
  }
  .smiths-table tr {
    text-align: left;
    border-bottom: 1px solid #fff;
  }
  .smiths-table tr th {
    font-weight: 600;
    border-bottom: 1px solid #fff;
    padding-bottom: 8px;
  }
  .smiths-table tr td {
    font-weight: 200;
    padding: 8px 0;
    border-bottom: 1px solid #fff;
  }

  .total-savings-container {
    width: 60%;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
  .total-savings-container h2 {
    color: #fff;
    background-color: #52b100;
    font-weight: 700;
    padding: 10px 20px;
    font-size: 24px;
  }

  .additional { padding: 60px 0; }
  .additional-container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px 0;
    background-color: #52b100;
    border-bottom: 10px solid #a8d87f;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .additional-container .icon img {
    width: 180px;
    max-width: none;
  }
  .additional-copy {
    text-align: left;
    width: 60%;
  }
  .additional-copy h2 {
    margin-bottom: 0;
    color: #fff;
  }
  .additional-copy p { margin: 20px 0 25px; }

  .bottom-lists {
    display: flex;
    flex-direction: row;
  }
  .bottom-lists .left {
    border-right: 1px solid #fff;
    width: 25%;
  }
  .bottom-lists .right-side {
    display: flex;
    flex-direction: row;
    width: 75%;
    justify-content: flex-end;
    align-items: flex-end;
    padding-left: 30px;
  }
  .bottom-lists .right-side .right { width: 100%; }

  .member-benefits {
    background-color: #52b100;
    padding: 60px 0;
    text-align: center;
  }
  .member-benefits h2 {
    color: #fff;
    margin-bottom: 10px;
  }
  .features-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: left;
    width: 80%;
    margin: 0 auto;
  }

  .member-benefits p.checkmark {
    background: transparent url(../assets/img/green-checkmark.png) no-repeat;
    background-size: 25px;
    background-position: left center;
    line-height: 25px;
    padding-left: 40px;
    font-weight: 300;
  }

  /*TABBED NAV*/
  .tabs-component ul li,
  .tabs-component-panel { color: #000; }
  .tabs-component ul li a {
    text-decoration: none;
    color: #636466;
  }
  .tabs-component ul li:hover a { color: #fff; }
  .tabs-component {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .tabs-component ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: center;
    margin: 0 auto;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #acacac;
  }
  .tabs-component ul li { margin-bottom: 10px; }
  .tabs-component ul li a {
    background-color: #e1e1e1;
    color: #636466;
    font-size: 18px;
    font-weight: 300;
    padding: 10px 20px;
    margin: 0 4px;
    transition: all 0.125s linear;
    cursor: pointer;
  }
  .tabs-component ul li a:hover {
    background-color: #52b100;
    color: #fff;
  }
  .tabs-component ul li.is-active a {
    background-color: #52b100;
    color: #fff;
  }
  .tabs-component ul li.is-active a { color: #fff; }

  @media (max-width: 800px) {
    .global-savings-header img.header-img { display: none; }
    .global-savings-header .header-copy { padding-top: 0; }
    .global-savings-header .header-copy h1 { font-size: 42px;  }

    .solution-intro { width: 90% !important; }

    .tabs-component ul {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    .tabs-component ul li {
      margin-bottom: 30px;
      width: 100%;
    }
    .tabs-component ul li a {
      width: 100%;
    }

    .row.savings-row { flex-direction: column; }
    .row.savings-row .savings-info {
      width: 100% !important;
      justify-content: center;
      text-align: center;
    }
    .row.savings-row .savings-info p { width: 100%; }
    .row.savings-row .brands {
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
    }
    .row.savings-row .brands.with-copy {
      flex-direction: column;
      align-items: center;
    }
    .row.savings-row .brands img {
      width: 50%;
      margin: 20px;
    }
    .row.savings-row .brands.with-copy img {
      width: 140px;
      margin: 0;
    }

    .hotel {
      flex-direction: column;
      width: 100%;
      align-items: center;
      margin-bottom: 20px;
    }
    .hotel p {
      text-align: center;
      font-size: 100%;
      margin: 0;
    }

    .jane-header,
    .smiths-header { flex-direction: column; }

    .janes-table, 
    .smiths-table { font-size: 96%; }
    .janes-table tr th,
    .smiths-table tr th {
      border: 1px solid #fff;
      padding: 4px;
    }
    .janes-table tr td,
    .smiths-table tr td {
      border: 1px solid #fff;
      padding: 4px;
    }

    .additional-container,
    .bottom-lists { flex-direction: column; }
    .bottom-lists .left {
      width: 100% !important;
      border: none;
    }
    .bottom-lists .left p,
    .bottom-lists .right p {
      margin: 0 0 20px 0;
    }
    .bottom-lists .right-side {
      width: 100%;
      padding: 0;
      align-items: center;
    }

    .features-list { flex-direction: column; }


  }
  
</style>