import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Tabs from 'vue-tabs-component';
import VueMoment from 'vue-moment';
import VueAnalytics from 'vue-analytics';
import Autocomplete from 'vue2-autocomplete-js';
// import firebase from 'firebase/app'
import vueNumeralFilterInstaller from 'vue-numeral-filter';

// COMPONENTS
import Nav from './components/Nav.vue'
import Footer from './components/Footer.vue'
import ColoursBar from './components/ColoursBar.vue'
import SvgLogo from './components/SvgLogo.vue'

// AXIOS FOR TREES API
import axios from 'axios'

const isProd = process.env.NODE_ENV == 'production';

Vue.prototype.$http = axios

Vue.config.productionTip = false

Vue.component('nav-component', Nav)
Vue.component('colours-bar', ColoursBar)
Vue.component('footer-component', Footer)
Vue.component('svg-logo', SvgLogo)
Vue.component('autocomplete', Autocomplete);

Vue.use(Tabs)
Vue.use(VueMoment)
Vue.use(vueNumeralFilterInstaller);
Vue.use(VueAnalytics, {
  id: 'UA-35228086-2',
  router,
  debug: {
    enabled: !isProd,
    sendHitTask: isProd
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags)
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    el => el.parentNode.removeChild(el)
  )

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})
