<template>
  <div class="reward-page-container">
    <nav-component></nav-component>
    <div class="reward-cloud-header">
      <div class="centering">
        <div class="header-copy">
          <img src="@/assets/img/reward-cloud.png" alt />
          <h1>One Cloud, Millions of Rewards</h1>
          <p>Over 10 million of the world’s coolest rewards are now available for your program. Reward Cloud delivers merchandise to 185 countries plus enables virtual rewards such as event tickets, retail &amp; prepaid gift cards, mobile top-up &amp; online travel.</p>
        </div>
        <img class="header-img" src="@/assets/img/reward-cloud-header.png" alt />
      </div>
    </div>
    <colours-bar></colours-bar>
    <div class="solution-intro">
      <p
        class="charcoal"
      >The Reward Cloud simplifies your program management with a single dashboard that centralizes all activities.</p>
    </div>

    <div class="personalized-storefront">
      <div class="container">
        <div class="copy">
          <h2>Personalized Storefront</h2>
          <p class="charcoal">
            Our member Storefront, personalized by our revolutionary Rewards AI, offers a beautiful, customizable interface that makes searching and redeeming more fun than ever. Using machine learning, the Personalized Storefront suggests rewards that are more personally meaningful for every individual.
          </p>
          <div class="learn-more">
            <button class="learn-more-btn charcoal-btn" @click="$router.push('/contact')">BOOK A DEMO</button>
          </div>
        </div>
        <div class="storefront-logo-container">
          <img class="fade-right in-view-trans" src="@/assets/img/personalized-storefront.png" alt />
        </div>
      </div>
    </div>

    <div class="storefront-api">
      <div class="container">
        <img class="storefront-img fade-left in-view-trans" src="@/assets/img/storefront.png" alt />
        <div class="copy">
          <h2>Storefront + API</h2>
          <p
            class="charcoal"
          >Our beautiful, customizable storefront interface is designed to make it easy for members to find and choose the rewards they want. Our robust, state-of-the-art technology consolidates millions of reward items, with constant updates to present the latest rewards&nbsp;options.</p>
          <div class="learn-more">
            <button class="learn-more-btn charcoal-btn" @click="$router.push('/contact')">BOOK A DEMO</button>
          </div>
        </div>
      </div>
    </div>

    <div class="salesforce-intg">
      <div class="container">
        <div class="copy">
          <h2>Salesforce Integration</h2>
          <p class="charcoal">
            Reward Cloud, Global Savings, and Gift Codes integrate into Salesforce through AppExchange. This means you can use our products with the most popular and widely used CRM software on the planet. By integrating our solutions into different systems, we ensure software uniformity and ease of access for our clients.
            <br />
            <br />Add the GRS App within Salesforce and instantly reward your sales or channel partners for their&nbsp;success.
          </p>
          <div class="learn-more">
            <a
              href="https://appexchange.salesforce.com/category/integration"
              target="_blank"
            >
              <button class="learn-more-btn charcoal-btn">GET IT NOW ON APPEXCHANGE</button>
            </a>
          </div>
        </div>
        <div class="salesforce-logo-container">
          <img class="simple-fade" src="@/assets/img/salesforce-blue.png" alt />
        </div>
      </div>
    </div>

    <div class="storefront-api">
      <div class="container">
        <img
          class="storefront-img fade-left in-view-trans"
          src="@/assets/img/custom-storefronts.png"
          alt
        />
        <div class="copy">
          <h2>Custom Branded Storefronts</h2>
          <p
            class="charcoal"
          >We can showcase your products and offers with beautifully designed custom branded storefronts. These unique pages feature your recognizable brand style and an innovative gallery that makes it easy to browse and buy from your curated selection of products, offers and&nbsp;experiences.</p>
          <div class="learn-more">
            <button class="learn-more-btn charcoal-btn" @click="$router.push('/contact')">BOOK A DEMO</button>
          </div>
        </div>
      </div>
    </div>

    <div class="innovative-body">
      <h2>Reward Cloud Features</h2>
      <div class="innovative-icons-container">
        <div class="row">
          <div class="innovative-icon with-transition-delay simple-fade">
            <div class="icon-holder true-blue">
              <img src="@/assets/img/innovation7.png" alt />
            </div>
            <p class="small charcoal">Create customized client galleries & catalogues</p>
          </div>
          <div class="innovative-icon simple-fade">
            <div class="icon-holder dark-green">
              <img src="@/assets/img/reward2.png" alt />
            </div>
            <p class="small charcoal">Reward data refreshes daily across all categories</p>
          </div>
          <div class="innovative-icon simple-fade">
            <div class="icon-holder dark-orange">
              <img src="@/assets/img/innovation4.png" alt />
            </div>
            <p class="small charcoal">Full order tracking and reporting</p>
          </div>
          <div class="innovative-icon simple-fade">
            <div class="icon-holder hot-pink">
              <img src="@/assets/img/merch.png" alt />
            </div>
            <p class="small charcoal">Automated daily merchandise and travel ‘Specials’</p>
          </div>
        </div>
        <div class="row">
          <div class="innovative-icon with-transition-delay simple-fade">
            <div class="icon-holder purp">
              <img src="@/assets/img/reward3.png" alt />
            </div>
            <p
              class="small charcoal"
            >98% of all items are delivered within 2-3 business days within the US</p>
          </div>
          <div class="innovative-icon simple-fade">
            <div class="icon-holder rose">
              <img src="@/assets/img/innovation8.png" alt />
            </div>
            <p
              class="small charcoal"
            >Automated global currency conversions to ensure financial integrity</p>
          </div>
          <div class="innovative-icon simple-fade">
            <div class="icon-holder peach">
              <img src="@/assets/img/innovation1.png" alt />
            </div>
            <p class="small charcoal">Global tax compliance and reporting</p>
          </div>
          <div class="innovative-icon simple-fade">
            <div class="icon-holder lilac">
              <img src="@/assets/img/innovation6.png" alt />
            </div>
            <p class="small charcoal">GRS Point Bank tracks all member point balances</p>
          </div>
        </div>
        <div class="row">
          <div class="innovative-icon with-transition-delay simple-fade">
            <div class="icon-holder pea-green">
              <img src="@/assets/img/bill.png" alt />
            </div>
            <p
              class="small charcoal"
            >Integrated with PayPal &amp; Apple Pay to enable members to securely top-up their points</p>
          </div>
          <div class="innovative-icon simple-fade">
            <div class="icon-holder next-blue">
              <img src="@/assets/img/wand.png" alt />
            </div>
            <p
              class="small charcoal"
            >Companies can customize the look and feel of their reward galleries by client</p>
          </div>
          <div class="innovative-icon simple-fade">
            <div class="icon-holder teal">
              <img src="@/assets/img/innovation5.png" alt />
            </div>
            <p class="small charcoal">Real-time reporting and order tracking</p>
          </div>
          <div class="innovative-icon simple-fade">
            <div class="icon-holder baby-blue">
              <img src="@/assets/img/innovation2.png" alt />
            </div>
            <p
              class="small charcoal"
            >24/7 global Tier 2 support team connected via an integrated ticketing system</p>
          </div>
        </div>
      </div>
    </div>

    <div class="grs-translates">
      <div class="main-translate-container">
        <h2 class="translate-header">The Reward Cloud is transforming engagement in every industry</h2>
        <div class="translate-row">
          <div class="copy-block left">
            <h2>Financial Institutions</h2>
            <p
              class="charcoal"
            >The Rewards Cloud is helping financial services organizations transform their interactions with their customers and employees. We help banks and insurance companies reward their loyal customers, boost employee engagement, and power up customer prospecting programs.</p>
            <div class="learn-more">
              <!-- <button class="learn-more-btn">LEARN MORE</button> -->
            </div>
          </div>
          <div class="img-container">
            <img src="@/assets/img/translate1.jpg" alt />
          </div>
        </div>
        <div class="translate-row">
          <div class="img-container">
            <img src="@/assets/img/translate2.jpg" alt />
          </div>
          <div class="copy-block right">
            <h2>Health & Wellness</h2>
            <p
              class="charcoal"
            >Wearable technology and activity tracking software is revolutionizing consumer health and wellness. We’re at the forefront of helping companies reward their members for living an active lifestyle, with a wide range of wellness-focussed rewards and configurable programs that inspire activity.</p>
            <div class="learn-more">
              <!-- <button class="learn-more-btn">LEARN MORE</button> -->
            </div>
          </div>
        </div>
        <div class="translate-row">
          <div class="copy-block left">
            <h2>Travel & Hospitality</h2>
            <p
              class="charcoal"
            >With more than 80% of airline passengers flying only twice a year and earning less than 5,000 mileage points, airlines have few low-value options to reward these customers, and accumulate a complex ‘point debt’. With Rewards Cloud, we can help airlines and hospitality companies minimize that liability by offering their members thousands of unique and attractive redemption options at lower point values.</p>
            <div class="learn-more">
              <!-- <button class="learn-more-btn">LEARN MORE</button> -->
            </div>
          </div>
          <div class="img-container">
            <img src="@/assets/img/translate3.jpg" alt />
          </div>
        </div>
        <div class="translate-row">
          <div class="img-container">
            <img src="@/assets/img/translate4.jpg" alt />
          </div>
          <div class="copy-block right">
            <h2>Retail</h2>
            <p
              class="charcoal"
            >We empower retail businesses by providing a robust reward solution for their employees and for their consumers. Using rewards to increase customer loyalty and employee engagement plays a big part in the retail industry, and our focus is on driving more traffic to your business. Earning points for shopping at a retailer increases brand loyalty and drives traffic.</p>
            <div class="learn-more">
              <!-- <button class="learn-more-btn">LEARN MORE</button> -->
            </div>
          </div>
        </div>
        <div class="translate-row">
          <div class="copy-block left">
            <h2>Fundraising</h2>
            <p
              class="charcoal"
            >Thousands of charities around the globe trust GRS to develop fundraising programs to help them fuel growth and expand their services. From schools to community groups, we can help your cause.</p>
            <div class="learn-more">
              <!-- <button class="learn-more-btn">LEARN MORE</button> -->
            </div>
          </div>
          <div class="img-container">
            <img src="@/assets/img/translate5.jpg" alt />
          </div>
        </div>
        <div class="translate-row">
          <div class="img-container">
            <img src="@/assets/img/translate6.jpg" alt />
          </div>
          <div class="copy-block right">
            <h2>Employee Engagement</h2>
            <p
              class="charcoal"
            >How emotionally committed are your employees to your business? If not truly engaged, an employee will not be as productive, curious, passionate and dedicated as you need them to be. Employee engagement is simply the most critical factor to your long term success. Our solutions are designed specifically to ignite employee engagement — helping you attract and retain top tier talent and boost productivity and profitability.</p>
            <div class="learn-more">
              <!-- <button class="learn-more-btn">LEARN MORE</button> -->
            </div>
          </div>
        </div>

        <div class="translate-row">
          <div class="copy-block left">
            <h2>Home Automation</h2>
            <p
              class="charcoal"
            >We may not (yet) have jetpacks, but for millions of people, smart home products are a simple way to bring the future into their home. Affordable and fun, our curated range of cameras, lights, smart speakers, switches, thermostats (and more!) can free your members from worrying about household ‘infrastructure’ and bring the rewards of security, control and comfort to their home.</p>
            <div class="learn-more">
              <!-- <button class="learn-more-btn">LEARN MORE</button> -->
            </div>
          </div>
          <div class="img-container">
            <img src="@/assets/img/translate7.jpg" alt />
          </div>
        </div>
        <div class="translate-row"></div>
        <div class="translate-row"></div>
        <div class="translate-row"></div>
      </div>
    </div>
    <footer-component></footer-component>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      name: 'reward-cloud'
    }
  }
}
</script>

<style scoped>


/*HEADER/HERO*/
.reward-cloud-header {
  background-color: #8a51b9;
  width: 100%;
  padding: 100px 0 30px;
}

.reward-cloud-header .centering {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}
.reward-cloud-header .header-copy { padding-top: 40px; }
.header-copy h1 {
  font-size: 60px;
  font-weight: 400;
  margin: 10px 0;
}
.header-copy p { width: 90%; }
.reward-cloud-header .header-img {
  width: 40%;
  height: auto;
}

/*MAIN PAGE BODY SECTION*/
.reward-page-container .solution-intro {
  width: 100% !important;
  margin: 0 auto;
  text-align: center;
  padding: 40px 0;
  background-color: #fff;
}

.personalized-storefront { background-color: #eee; }
.personalized-storefront .container {
  padding: 40px 0;
  width: 80%;
  max-width: 1200px;
}
.personalized-storefront .copy { width: 40%; }
.personalized-storefront .container p { width: 100%; }
.storefront-logo-container {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}
.storefront-logo-container img {
  width: 90%;
  -webkit-filter: drop-shadow(0px 3px 9px #d2d2d2);
  filter: drop-shadow(0px 3px 9px #d2d2d2);
}

.innovative-body {
  background-color: #eee;
  padding: 60px 0;
  text-align: center;
}

.innovative-icons-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}
.innovative-icons-container .row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 60%;
  margin-bottom: 30px;
}
.innovative-icons-container .row p.small {
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 10px 0;
  text-align: center;
}

.innovative-icon { max-width: 130px; }

.icon-holder {
  width: 20px;
  height: 20px;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 15px;
}
.icon-holder img {
  width: 60px !important;
  position: absolute;
}

.dark-orange { background-color: #ff6b00; }
.dark-green { background-color: #52b100; }
.sky-blue { background-color: #6dcff6; }
.pink { background-color: #f49ac1; }
.peach { background-color: #fcb562; }
.purp { background-color: #8a51b9; }
.pea-green { background-color: #7cc576; }
.hot-pink { background-color: #ec008c; }
.lilac { background-color: #c19ade; }
.true-blue { background-color: #00b1ff; }
.next-blue { background-color: #406db5; }
.teal { background-color: #1cbbb4; }
.baby-blue { background-color: #6dcff6; }

.storefront-api {
  height: 580px;
  background-color: #fff;
}
.container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.container h2 { margin-bottom: 20px; }
.container p {
  width: 70%;
  margin-bottom: 40px;
}

.storefront-img {
  height: 680px;
  position: relative;
  top: -40px;
}

.storefront-api .copy {
  position: relative;
  top: -50px;
  left: 40px;
}

.salesforce-intg { background-color: #eee; }
.salesforce-intg .container {
  width: 80%;
  max-width: 1200px;
  padding: 80px 0;
}


.main-translate-container { padding: 60px 0; }
.main-translate-container h2 { text-align: center; }

.grs-translates { background-color: #fff; }

.translate-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto 40px;
}
.translate-row .img-container img { max-width: 650px; }
.translate-row .copy-block {
  background-color: #fff;
  border-bottom: 10px solid #8a51b9;
  padding: 40px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 40px;
}
.translate-row .copy-block.left { left: 80px; }
.translate-row .copy-block.right { right: 80px; }
.translate-row .copy-block h2 {
  text-align: left;
  margin-bottom: 20px;
}
@media screen and (min-width: 1020px) and (max-width: 1150px) {
  .container p { width: 95%; }
}
@media (max-width: 1020px) {
  .reward-cloud-header img.header-img { display: none; }
  .reward-cloud-header .header-copy { padding-top: 0; }
  .reward-cloud-header .header-copy h1 { font-size: 42px; }

  .storefront-logo-container img { width: 100%; }

  .solution-intro { width: 90% !important; }

  .innovative-body { padding-bottom: 10px; }

  .innovative-icons-container .row {
    flex-wrap: wrap;
    width: 100%;
  }

  .storefront-api {
    height: auto;
    padding-bottom: 80px;
  }

  .personalized-storefront .copy {
    width: 90%;
    text-align: center;
  }
  .personalized-storefront .container { padding-top: 60px; }
  .storefront-logo-container { width: 90%; }
  .personalized-storefront .container,
  .storefront-api .container {
    flex-direction: column;
    width: 100%;
    height: auto;
  }
  .storefront-api .copy {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: -20px;
    left: 0;
  }
  .storefront-img {
    width: 90%;
    height: auto;
  }
  .salesforce-intg .container {
    flex-direction: column;
    align-items: center;
  }
  .salesforce-intg .copy { text-align: center; }
  .salesforce-intg .copy p { width: 100%; }
  .salesforce-intg .container .salesforce-logo-container,
  .storefront-logo-container { margin-top: 60px; }

  .translate-header {
    width: 90%;
    margin: 0 auto;
  }
  .grs-translates .translate-row { flex-direction: column; }
  .grs-translates .translate-row .copy-block {
    width: 90%;
    margin: 0 auto 40px;
    box-sizing: border-box;
  }
  .grs-translates .translate-row .copy-block.left { left: 0; }
  .grs-translates .translate-row .copy-block.right { right: 0; }

  .translate-row .img-container img {
    width: 100%;
    margin: 0 auto 40px;
  }
}

@-moz-document url-prefix() {
  .reward-page-container .reward-cloud-header .header-img {
    width: 90%;
  }
}
</style>
